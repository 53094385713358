/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import HelmetSEO from 'components/templates/Helmet';
import PolicyLayout from 'components/templates/PolicyLayout';
import { getPolicyByID } from 'services/policies';

const PolicyDelivery: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = React.useState<string>('');
  const [policyRules, setPolicyRules] = React.useState<string>('');
  const fetchPocilyRules = async () => {
    try {
      const res = await getPolicyByID(15);
      setPolicyRules(res?.data?.body);
      setTitle(res?.data?.title);
    } catch (error) {
      //
    }
  };
  useEffect(() => {
    if (location.pathname) {
      fetchPocilyRules();
    } else {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <>
      <HelmetSEO
        title={title ? `${title} | BidaHub` : 'BidaHub'}
        description='BidaHub là một đơn vị chuyên cung cấp các thiết bị bida chính hãng phù hợp với tất cả các người chơi'
        url='https://bidahub.com/policy-delivery'
        image='https://bidahub.com/static/media/logo.1161657e3f26c20823a0.png'
        siteName='BidaHub'
      />
      <PolicyLayout heading={title || 'Chính sách giao nhận và thanh toán'}>
        <div dangerouslySetInnerHTML={{
          __html: policyRules
        }}
        />
      </PolicyLayout>
    </>
  );
};

export default PolicyDelivery;
