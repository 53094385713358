import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import HelmetSEO from 'components/templates/Helmet';
import ProductDetailContainer from 'containers/ProductContainer.tsx/Detail';
import { setProductDetail } from 'store/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import SLUG_PARAMS from 'utils/constants';

const ProductDetail: React.FC = () => {
  const { title, id, image } = useAppSelector((state) => state.general.product);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(setProductDetail({
      title: '',
      id: '',
      image: ''
    }));
  }, [location.pathname.includes(SLUG_PARAMS.PRODUCTS_DETAIL)]);

  return (
    <>
      <HelmetSEO
        title={title ? `${title} | BidaHub` : 'BidaHub'}
        description='BidaHub là một đơn vị chuyên cung cấp các thiết bị bida chính hãng phù hợp với tất cả các người chơi'
        url={`https://bidahub.com/${SLUG_PARAMS.PRODUCTS_DETAIL}/${id}`}
        image={image || 'https://bidahub.com/static/media/logo.1161657e3f26c20823a0.png'}
        siteName='BidaHub'
      />
      <div className='p-productDetail bg-page'>
        <ProductDetailContainer />
      </div>
    </>
  );
};

export default ProductDetail;
