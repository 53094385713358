/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import React, { FC, useMemo } from 'react';
import Slider, { Settings } from 'react-slick';

import StarRating from '../../molecules/StarRating';
import BackgroundBadge from '../BackgroundBadge';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Icon from '../Icon';

import QuantitySelector from 'components/molecules/QuantitySelector';
import ContentPanel from 'components/organisms/ContentPanel';
import useWindowDimensions from 'hooks/useWindowDemensions';

interface SkeletonProps {
  className?: any;
  props?: any;
  children?: React.ReactNode;
  size?: string;
}

export const TextSkeleton: FC<SkeletonProps> = ({ className, props, children }) => (
  <p
    {...props}
    className={`bg-gainsboro text-transparent w-fit h-fit animate-pulse ${className}`}
  >
    {children}
  </p>
);

export const ImageSkeleton: FC<SkeletonProps> = ({ className, props }) => (
  <div
    {...props}
    className={`bg-gainsboro animate-pulse ${className ?? ''}`}
  />
);

export const ButtonSkeleton: FC<SkeletonProps> = ({
  className, props, size, children
}) => (
  <button
    {...props}
    size={size as string}
    className={`bg-gainsboro text-transparent w-full h-fit animate-pulse ${className}`}
  >{children}
  </button>
);

export const BannerSliderSkeleton = () => (
  <div>
    <ImageSkeleton className='w-full aspect-375/216 lg:aspect-1440/446' />
  </div>
);

export const ProductCardSkeleton = () => (
  <div className='o-productCard rounded-xl hover:shadow-md p-2 sm:p-3 bg-white base-transition'>
    <div
      className='relative group'
    >
      <div className='o-productCard__image w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden'>
        <ImageSkeleton
          className='object-cover w-full h-full rounded-lg transform transition duration-500 hover:scale-110'
        />
      </div>
    </div>
    <div className='mt-2 sm:mt-3'>
      <div>
        <TextSkeleton className='o-productCard__title text-base lg:text-lg font-semibold line-clamp-2'>
          Đuôi gậy phá hãng Predator
        </TextSkeleton>
      </div>
      <div className='flex mt-4 sm:mt-[22px] justify-between'>
        {/* <TextSkeleton className='o-productCard__category hidden sm:block font-medium text-sm text-feldgrau'>Bi cais</TextSkeleton>
        <TextSkeleton className='o-productCard__brand font-medium text-sm text-feldgrau'>Predator</TextSkeleton> */}
      </div>
      <div className='my-2 border-t hidden sm:block border-gainsboro w-full' />
      <TextSkeleton className='hidden sm:block'>
        3.000.000
      </TextSkeleton>
      <TextSkeleton className='hidden sm:block'>
        3.000.000
      </TextSkeleton>
      <div className='flex items-center mt-1 mb-2 sm:mt-2 sm:mb-3'>
        <StarRating isStatic count={5} value={0} isResponsive />
        <TextSkeleton className='rating-text text-sm lg:text-md ml-1 2xl:ml-2'>Đánh giá</TextSkeleton>
      </div>
      <div className='flex items-center justify-between'>
        <TextSkeleton className='block sm:hidden'>
          3.000.000
        </TextSkeleton>
      </div>
      <ButtonSkeleton className='hidden p-3 sm:flex' size='large'>
        Thêm vào giỏ hàng
      </ButtonSkeleton>
    </div>
  </div>
);

export const CategoryCardSkeleton = () => (
  <div className='flex'>
    <ButtonSkeleton className='m-categoryCard flex cursor-pointer px-3 py-[10px] md:px-4 md:py-2 bg-white rounded-lg hover:shadow-md base-transition justify-between'>
      <div className='min-w-[160px] text-left'>
        <TextSkeleton className='text-md md:text-base font-semibold line-clamp-1'>Bi/Ball</TextSkeleton>
        <TextSkeleton className='text-sm'>10 sản phẩm</TextSkeleton>
      </div>
      <div className='flex-none w-[40px] h-[40px]'>
        <ImageSkeleton className='w-full h-full object-cover' />
      </div>
    </ButtonSkeleton>
  </div>
);
export const PartnersSlideSkeleton = () => {
  const data = [...new Array(5)];
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: data.length >= 5 ? 5 : data.length,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding: '20px',
          infinite: true
        },
      },
    ],
  };

  return (
    <div className='t-partnersSlide'>
      <p className='max-w-lg text-xl lg:text-2xl font-semibold leading-normal mb-3'>
        Đối tác
      </p>
      <div className='slick-rtl'>
        <Slider {...settings}>
          {
            data.map(() => (
              <div className='px-1.5 md:p-2 aspect-2/1 focus:outline-none'>
                <ImageSkeleton className='w-full h-full object-cover rounded-xl' />
              </div>
            ))
          }
        </Slider>
      </div>
    </div>
  );
};

export const MenuSkeleton = () => {
  const data = [...new Array(5)];
  return (
    <div className='flex gap-3'>
      {
        data.map(() => (
          <ButtonSkeleton className='h-8' size='large'>
            Danh mục
          </ButtonSkeleton>
        ))
      }
    </div>
  );
};

export const DetailProductSkeleton = () => (
  <div className='lg:w-4/12 py-4 px-4 lg:px-6 lg:border-l lg:border-r lg:border-gainsboro'>
    <BackgroundBadge icon={<Icon iconName='verify' size='16x16' />}>
      Chính hãng 100%
    </BackgroundBadge>
    <TextSkeleton className='product-sku  mt-2'>SKU: 123456789</TextSkeleton>
    <TextSkeleton className='product-title text-xl font-semibold mt-2'>
      Bi Cái Aramith Logo Đen
    </TextSkeleton>
    <div className='product-rating flex items-center py-3'>
      <StarRating
        count={5}
        value={0}
        isStatic
      />
      <TextSkeleton className='text-md text-brilliant-azure ml-1'>
        5 đánh giá
      </TextSkeleton>
    </div>
    <TextSkeleton className='product-price text-2xl font-semibold'>
      3.000.000đ
    </TextSkeleton>
    <div className='h-[1px]  bg-gainsboro w-full my-6 hidden lg:block' />
    <div className='bottom mt-4 lg:mt-6'>
      <TextSkeleton className='text-gunmetal font-semibold mb-3'> Số lượng</TextSkeleton>
      <QuantitySelector
        modifier='lg'
      />
      <div className='mt-3'>
        <TextSkeleton className='inline-block border rounded bg-honeydew px-3 py-[6px] text-md text-center'>
          Còn hàng
        </TextSkeleton>
      </div>
      <div className='mt-3 isLiked'>
        <ButtonSkeleton
          className='flex items-center hover:cursor-pointer'
        >
          <TextSkeleton className='inline-block ml-1 text-gunmetal text-md'>
            Thêm vào DS yêu thích
          </TextSkeleton>
        </ButtonSkeleton>
      </div>
    </div>
    <div className='addToCart mt-4 lg:mt-6'>
      <TextSkeleton className=' text-md text-center mt-2'>*Bạn sẽ được thông báo khi sản phẩm có hàng trở lại</TextSkeleton>
    </div>
    <ButtonSkeleton className='w-full h-14 rounded-lg mt-4'>
      Thêm vào giỏ hàng
    </ButtonSkeleton>
  </div>
);

export const SlideShowViewerSkeleton = () => {
  const data = [...new Array(5)];

  const mainSettings: Settings = {
    autoplay: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: false,
    dots: false,
    infinite: false,
  };
  const navSettings: Settings = {
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    focusOnSelect: true,
    infinite: false,
  };

  return (
    <div className='o-slideshowViewer bg-white p-2 relative'>
      <div className='o-slideshowViewer__thumbnail'>
        <Slider
          {...mainSettings}
        >
          {data?.map((item, idx) => (
            <div key={`item-${idx.toString()}`} className=''>
              <div className='aspect-[4/3] p-2'>
                <ImageSkeleton className='w-full h-full object-cover' />
              </div>
            </div>
          ))}
        </Slider>
        {/* Arrows  */}
        <div className='justify-end items-center gap-2 p-2 hidden lg:flex'>
          <ButtonSkeleton aria-label='arrow' className='flex justify-center items-center w-8 h-8 rounded shadow  text-white base-transition hover:bg-white hover:text-raisin-black'>
            <svg className='w-7 h-7' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
              <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m14 8-4 4 4 4' />
            </svg>
          </ButtonSkeleton>
          <ButtonSkeleton aria-label='arrow' className='flex justify-center items-center w-8 h-8 rounded shadow text-white base-transition hover:bg-white hover:text-raisin-black'>
            <svg className='w-7 h-7' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
              <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m10 16 4-4-4-4' />
            </svg>
          </ButtonSkeleton>
        </div>
        {/* Index  */}
        <div className='justify-end items-center px-2 flex lg:hidden absolute right-5 bottom-9' />
      </div>
      <div className='o-slideshowViewer__navigator hidden lg:block'>
        <Slider
          {...navSettings}
        >
          {data.map((item, idx) => (
            <div key={`item-${idx.toString()}`} className='p-2'>
              <div className='aspect-1 p-2 border-2 base-transition'>
                <ImageSkeleton className='w-full h-full object-cover' />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export const ProductInfoSkeleton = () => (
  <div className='t-productInfo text-raisin-black'>
    <div className='flex flex-col lg:flex-row gap-3 lg:gap-8'>
      <div className='lg:w-1/2'>
        <h3 className='text-xl md:text-2xl font-semibold mb-4'>Thông tin sản phẩm</h3>
        <TextSkeleton className='text-3xl lg:text-4xl font-semibold gradient-text leading-tight'>
          BidaHUB
        </TextSkeleton>
        <TextSkeleton className='mt-4'>
          Bi Cái Aramith Logo Đen là một quả bóng chất lượng cao được thiết kế dành cho cả những tay chơi bida chuyên nghiệp và giải trí.
        </TextSkeleton>
      </div>
      <div className='lg:w-1/2'>
        <div className=' bg-anti-flash-white rounded-lg overflow-hidden'>
          <div className='px-4 lg:p-6'>
            <TextSkeleton className=''>
              Thông tin sản phẩm:

              Thương hiệu: ARAMITH
              Nguồn gốc – Xuất xứ: Bỉ
              Chất liệu: Nhựa phenolic
              Màu sắc: Trắng logo đen
              Đường kính bi: 57.2 mm
              Phân loại: Bi chủ, Cue balls
              Bảo hành: 12 tháng
              Sản phẩm bao gồm: 1 bi cái

              Mô tả sản phẩm:

              Được sản xuất bởi Aramith, nhà lãnh đạo nổi tiếng toàn cầu trong lĩnh vực bida, quả bóng chủ này kết hợp giữa độ chính xác, độ bền và phong cách để nâng cao trải nghiệm chơi bida của bạn.
            </TextSkeleton>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ReviewItemSkeleton = () => (
  <div className='flex'>
    {/* Avatar  */}
    <ImageSkeleton
      className='w-12 h-12 rounded-full mr-4'
    />
    <div className='flex-1'>
      {/* Author  */}
      <div className='flex items-center justify-between mb-4'>
        <div className=''>
          <TextSkeleton className='text-md font-semibold text-gunmetal'>BIDAHUB BIDAHUB</TextSkeleton>
          <StarRating value={0} isStatic />
        </div>
        <TextSkeleton className='text-sm '>20-20-2020 20:20</TextSkeleton>
      </div>
      {/* Content  */}
      <TextSkeleton className='font-semibold text-xl mb-1'>BIDAHUB BIDAHUB</TextSkeleton>
      <TextSkeleton className=' mb-4'>BIDAHUB BIDAHUB</TextSkeleton>
      <div className='mt-4'>
        <TextSkeleton className='text-gunmetal text-md'>Bạn có thấy đánh giá này hữu ích không?</TextSkeleton>
        {/* Like & Dislike  */}
        <div className='flex flex-row gap-3 mt-2'>
          <ButtonSkeleton
            className='base-button-style px-4 flex items-center py-2 text-md rounded-lg h-9 w-auto bg-white border  '
          >
            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M6.99121 15.4083V6.94166C6.99121 6.60832 7.09121 6.28332 7.27454 6.00832L9.54954 2.62499C9.90788 2.08332 10.7995 1.69999 11.5579 1.98332C12.3745 2.25832 12.9162 3.17499 12.7412 3.99166L12.3079 6.71666C12.2745 6.96666 12.3412 7.19166 12.4829 7.36666C12.6245 7.52499 12.8329 7.62499 13.0579 7.62499H16.4829C17.1412 7.62499 17.7079 7.89166 18.0412 8.35832C18.3579 8.80832 18.4162 9.39166 18.2079 9.98332L16.1579 16.225C15.8995 17.2583 14.7745 18.1 13.6579 18.1H10.4079C9.84954 18.1 9.06621 17.9083 8.70788 17.55L7.64121 16.725C7.23288 16.4167 6.99121 15.925 6.99121 15.4083Z' />
              <path d='M4.34199 5.31668H3.48366C2.19199 5.31668 1.66699 5.81668 1.66699 7.05001V15.4333C1.66699 16.6667 2.19199 17.1667 3.48366 17.1667H4.34199C5.63366 17.1667 6.15866 16.6667 6.15866 15.4333V7.05001C6.15866 5.81668 5.63366 5.31668 4.34199 5.31668Z' />
            </svg>

            <TextSkeleton className='ml-1'>Có</TextSkeleton>
          </ButtonSkeleton>
          <ButtonSkeleton
            className='base-button-style px-4 flex items-center py-2 text-md rounded-lg h-9 w-auto bg-white border '
          >
            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M13.0082 4.58333V13.05C13.0082 13.3833 12.9082 13.7083 12.7249 13.9833L10.4499 17.3667C10.0915 17.9083 9.19985 18.2917 8.44152 18.0083C7.62485 17.7333 7.08318 16.8167 7.25818 16L7.69152 13.275C7.72485 13.025 7.65818 12.8 7.51652 12.625C7.37485 12.4667 7.16652 12.3667 6.94152 12.3667H3.51652C2.85818 12.3667 2.29152 12.1 1.95818 11.6333C1.64152 11.1833 1.58318 10.6 1.79152 10.0083L3.84152 3.76666C4.09985 2.73333 5.22485 1.89166 6.34152 1.89166H9.59152C10.1499 1.89166 10.9332 2.08333 11.2915 2.44166L12.3582 3.26666C12.7665 3.58333 13.0082 4.06666 13.0082 4.58333Z' />
              <path d='M15.6585 14.675H16.5168C17.8085 14.675 18.3335 14.175 18.3335 12.9417V4.56666C18.3335 3.33333 17.8085 2.83333 16.5168 2.83333H15.6585C14.3668 2.83333 13.8418 3.33333 13.8418 4.56666V12.95C13.8418 14.175 14.3668 14.675 15.6585 14.675Z' />
            </svg>
            <span className='ml-1'>Không</span>
          </ButtonSkeleton>
        </div>
      </div>
    </div>
  </div>
);

export const ProductReviewSkeleton = () => {
  const data = [...new Array(5)];
  return (
    <div className='t-productReviews'>
      <div className='t-productReviews__head pb-6 border-gainsboro border-b'>
        <p className='text-xl lg:text-2xl font-semibold mb-2'>
          Đánh giá sản phẩm
        </p>
        <div className='flex flex-col md:flex-row md:justify-between md:items-center gap-4'>
          <div className='star-rating'>
            <TextSkeleton className='text-gunmetal mb-1'>
              100 Đánh giá
            </TextSkeleton>
            <StarRating size='24x24' isStatic count={5} value={0} />
          </div>
          <ButtonSkeleton size='large' className='md:w-[200px] h-14 rounded-lg'>
            Viết Đánh Giá
          </ButtonSkeleton>
        </div>
      </div>
      {
        data && data.length ? (
          <div className='mt-6 flex flex-col gap-6'>
            {
              data.map((item, index) => (
                <div key={`item-${index.toString()}`}>
                  <ReviewItemSkeleton />
                </div>
              ))
            }
          </div>
        )
          : (
            <div className='t-productReviews__empty flex flex-col items-center text-center max-w-[227px] mx-auto py-12'>
              <ImageSkeleton />
              <TextSkeleton className='mt-4 mb-2 font-semibold text-gunmetal'>Chưa có đánh giá</TextSkeleton>
              <TextSkeleton className='text-feldgrau text-md'>Tiến hành đặt hàng để viết bài đánh giá cho sản phẩm này</TextSkeleton>
            </div>
          )
      }
    </div>
  );
};

export const ProductTableSkeleton = () => {
  // const data = [...new Array(5)];
  const { width } = useWindowDimensions();
  const products = [...new Array(5)];

  if (width >= 992) {
    return (
      <table className='t-productTable w-full bg-white rounded-xl'>
        <thead>
          <tr className='text-feldgrau'>
            <th className='p-3 font-semibold'>
              <Checkbox />
            </th>
            <th className='p-3 font-semibold text-left w-[50%]'>Sản phẩm</th>
            <th className='p-3 font-semibold text-center w-[15%]'>Giá</th>
            <th className='p-3 font-semibold text-center w-[15%]'>Số lượng</th>
            <th className='p-3 font-semibold text-right w-[15%]'>Tổng</th>
          </tr>
        </thead>
        <tbody>
          {products?.map((product) => {
            const discount = product?.discount || 0;
            return (
              <tr key={product?.id} className='border-t text-gunmetal'>
                <td className='p-3'>
                  <Checkbox />
                </td>
                <td className='p-3 flex items-center space-x-4 image'>
                  <ImageSkeleton className='w-full h-20 object-cover' />
                  <div>
                    <TextSkeleton className='product-name'>{product?.name}</TextSkeleton>
                    {product?.attributeInfo.length > 0 && (
                      <div className='flex space-x-2 flex-wrap'>
                        <ButtonSkeleton
                          className='text-md rounded-lg'
                        />
                        <ButtonSkeleton
                          className='text-md rounded-lg'
                        />
                      </div>
                    )}
                  </div>
                </td>
                <td className='p-3 text-center price'><TextSkeleton>20000000</TextSkeleton></td>
                <td className='p-3 text-center'>
                  <div className='flex justify-center'>
                    <ButtonSkeleton className='h-12'>213412412512</ButtonSkeleton>
                  </div>
                </td>
                <td className='p-3 text-right'>
                  <TextSkeleton className='font-semibold'>20000000000000</TextSkeleton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  // Mobile version
  return (
    <div className='t-productTable__mobile bg-white rounded-lg overflow-hidden'>
      <div className='px-4 py-3 border-b'>
        <h2 className='md:text-lg font-semibold'>Sản phẩm</h2>
      </div>
      {products?.map((product) => (
        <div key={product?.id} className='flex flex-col border-b px-2 py-4'>
          <div className='flex items-center space-x-4'>
            <Checkbox />
            <div className='px-2'>
              <ButtonSkeleton className='aspect-1 w-[72px] h-full block rounded-lg overflow-hidden'>
                <ImageSkeleton className='w-full h-full object-cover' />
              </ButtonSkeleton>
            </div>
            <div className='flex-1'>
              <TextSkeleton className='product-name'>{product?.name}</TextSkeleton>
              <div className='flex space-x-2 flex-wrap'>
                <TextSkeleton
                  className='rounded-md border border-gainsboro p-2 min-w-[36px] active:bg-blueRyb active:text-white button-base-style'
                >
                  Bi cái màu trắng siêu đẹp
                  <ImageSkeleton className='ml-1' />
                </TextSkeleton>
              </div>
              <div className='flex flex-col gap-2'>
                <TextSkeleton className='mt-2'>Giá: 200000</TextSkeleton>
                <TextSkeleton className='font-semibold'>Tổng: 21846712</TextSkeleton>
                <TextSkeleton className='text-carmine-pink text-sm'>Tiết kiệm: 213412</TextSkeleton>
                <div className='bottom flex justify-between items-center'>
                  <ButtonSkeleton className='mr-2 h-10'>1241254</ButtonSkeleton>
                  <div className='flex gap-4'>
                    <div className='trash'>
                      <ButtonSkeleton
                        className='w-9 h-9 rounded-lg flex items-center justify-center bg-anti-flash-white2 base-transition hover:bg-gray-300'
                      >
                        <Icon iconName='trashOutlined' size='24x24' />
                      </ButtonSkeleton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const OrderSumarySkeleton: React.FC = () => {
  const isCompleted = true;
  const { width } = useWindowDimensions();
  const isDesktop = useMemo(() => width >= 1024, [width]);

  const desktopContent = () => (
    <div className='o-orderSummary__desktop p-4 lg:p-6 bg-white rounded-xl overflow-hidden'>
      <h2 className='text-lg font-semibold mb-4'>Tóm tắt đơn hàng</h2>

      <div className='flex justify-between mb-2 text-gunmetal'>
        <span>Tạm tính</span>
        <TextSkeleton>1.000.000</TextSkeleton>
      </div>
      <div className='flex justify-between mb-2'>
        <span className='text-gunmetal'>Giảm giá</span>
        <TextSkeleton className=''>1.000.000</TextSkeleton>
      </div>
      <div className='flex justify-between mb-2 text-gunmetal'>
        <div className='text-gunmetal'>
          <p>Điểm thưởng đã áp dụng</p>
          {/* <p className='text-roman-silver text-sm mt-1'>{orderSummary?.redeemedRewardPoints} BCoins</p> */}
        </div>
        <TextSkeleton className=''>1.000.000</TextSkeleton>
      </div>
      <div className='flex justify-between mb-2 text-gunmetal'>
        <span>Vận chuyển</span>
        <span>Miễn phí</span>
      </div>

      <div className='border-t border-gainsboro my-4' />

      <div className='flex justify-between items-center mb-2'>
        <span className='text-xl font-semibold text-gunmetal'>Thành tiền:</span>
        <TextSkeleton className='text-xl font-semibold'>1.000.000</TextSkeleton>
      </div>
      <div className='submit mb-3'>
        <ButtonSkeleton size='large' className='text-white h-12 rounded-md  capitalize'>
          Mua lại
        </ButtonSkeleton>
      </div>
      <div className='text-gunmetal mt-3 font-medium flex justify-between'>
        <p>Điểm thưởng sẽ được nhận</p>
        <TextSkeleton className=''>1.000.000 Bcoin</TextSkeleton>
      </div>
      <div className='text-sm text-gray-700 bg-alice-blue px-3 py-2 rounded-lg mt-3'>
        Điểm thưởng sẽ được kích hoạt sau khi đơn hàng được giao cho bạn. Để biết thêm thông tin, vui lòng xem
        <a href='/' target='_blank' className='text-brilliant-azure'>
          TẠI ĐÂY
        </a>
      </div>
      {/* TODO: In case the order completed with waiting payment status  */}
      <div className='text-sm text-gray-700 bg-anti-flash-white2 px-3 py-2 rounded-lg mt-3'>
        <span>Để xuất hoá đơn VAT xin vui lòng liên hệ Zalo hoặc số điện thoại hotline</span>{' '}
        <span className='text-brilliant-azure'>
          0949.696.696
        </span>
      </div>
    </div>
  );

  const mobileContent = () => (
    <div className='o-orderSummary__mobile fixed bottom-0 left-0 right-0 bg-white z-50'>
      <button type='button' className='flex py-3 px-4 justify-between items-center w-full bg-blueRyb text-white text-md' onClick={() => { }}>
        <p className='flex gap-4'>
          Bạn đang có{' '}
          <TextSkeleton className='font-semibold'>1.000.000 Bcoin</TextSkeleton>
        </p>
        <Icon iconName='chevronRightWhite' size='16x16' />
      </button>
      <button type='button' className='flex py-3 px-4 justify-between items-center w-full' onClick={() => { }}>
        <p className='text-md text-gunmetal'>Sử dụng voucher</p>
        <p className='text-roman-silver flex items-center text-md'>Nhập hoặc chọn mã
          <Icon iconName='chevronRight' size='16x16' />
        </p>
      </button>
      <div className='submit py-3 px-4 border-1 border-gainsboro'>
        <div className='flex flex-row gap-4 items-center'>
          <div className='w-6/12'>
            <p className='text-gunmetal text-md'>Tạm tính</p>
            <TextSkeleton className='text-xl  font-semibold'>1.000.000</TextSkeleton>
            <p className='text-sm text-gunmetal'>Tiết kiệm: {' '}
              <TextSkeleton className=''>1.000.000</TextSkeleton>
            </p>
          </div>
          <div className='w-6/12'>
            <ButtonSkeleton size='large'>
              Đặt hàng
            </ButtonSkeleton>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className='o-orderSummary'>
      {isCompleted ? desktopContent() : isDesktop ? desktopContent() : mobileContent()}
    </div>
  );
};

export const OrderDetailSkeleton: React.FC = () => {
  const isUser = true;
  return (
    <div className='mx-auto'>
      <div className='lg:flex gap-6'>
        <div className='w-full lg:w-8/12'>
          <div className='flex flex-col gap-4 lg:gap-6'>
            <ContentPanel contentClassName='p-6'>
              <div className='info mt-2'>
                {
                  isUser
                    ? (
                      <h3 className='text-lg py-3 px-6 font-semibold border-gainsboro border-b-1'>
                        Thông tin chi tiết đơn hàng
                      </h3>
                    )
                    : (
                      <h3 className='text-xl lg:text-2xl font-semibold py-3 border-gainsboro border-b-1'>
                        Thông tin chi tiết đơn hàng
                      </h3>
                    )
                }
                <div className={`detail flex flex-col gap-2 ${isUser ? 'p-4 lg:p-6' : 'mt-4 lg:mt-6'}`}>
                  <p className='font-semibold text-lg flex gap-2'>
                    ID đơn hàng: <TextSkeleton className=''>#111</TextSkeleton>
                  </p>
                  <div className='flex justify-between text-gunmetal'>
                    <p>Ngày đặt:</p>
                    <TextSkeleton>11/11/1970</TextSkeleton>
                  </div>
                  <div className='flex justify-between text-gunmetal'>
                    <p>Trạng thái đơn hàng: </p>
                    {/* TODO: For only waiting payment status  */}
                    <TextSkeleton> Đang xử lý</TextSkeleton>
                  </div>
                </div>
                {
                  !isUser
                  && (
                    <div className='text-gunmetal bg-alice-blue p-3 lg:p-4 mt-4 lg:mt-6 rounded-lg'>
                      Khi cần hỗ trợ vui lòng gọi {' '}
                      <a href='tel:0392555999' className='text-brilliant-azure font-semibold'>0392.555.999</a>
                      {' '}
                      (8:00 - 20:00)
                    </div>
                  )
                }
              </div>
            </ContentPanel>
            <ContentPanel contentClassName='p-0'>
              <ProductTableSkeleton />
            </ContentPanel>
          </div>
        </div>
        <div className='w-full lg:w-4/12 mt-4 lg:mt-0'>
          <OrderSumarySkeleton />
        </div>
      </div>
    </div>
  );
};
