import { Download } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { bankList } from 'assets/dummy';
import qrCodeIcon from 'assets/images/qrCode.svg';
import Button from 'components/atoms/Button';
import CustomModal from 'components/organisms/Modal';
import { getOrderDetailData } from 'services/order';
import { setOrderId } from 'store/cart';
import { useAppDispatch } from 'store/hooks';
import { formatVndCurrency } from 'utils/functions';

interface ProcessPaymentModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  qrCode?: any;
  minutes: number;
  // onChangeMethod?: () => void;
  onCountdownEnd?: () => void; // New prop for countdown end
}

const instructions: React.ReactNode[] = [
  <>Mở ứng dụng ngân hàng hỗ trợ VIETQR trên điện thoại</>,
  <>Trên ứng dụng chọn tính năng <span className='inline-flex items-center'> <img src={qrCodeIcon} alt='qr' width={24} height={24} /> Quét mã QR  </span>
  </>,
  <>Quét QR ở trang này và thanh toán</>
];

const ProcessPaymentModal: React.FC<ProcessPaymentModalProps> = ({
  isOpen, qrCode = {}, onRequestClose, minutes, onCountdownEnd
}) => {
  const [time, setTime] = useState(minutes * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          if (onCountdownEnd) {
            onCountdownEnd(); // Trigger the countdown end callback
          }
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [onCountdownEnd]);

  const displayTime = useMemo(() => {
    const min = Math.floor(time / 60);
    const seconds = time % 60;
    return { min, seconds };
  }, [time]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSuccess = () => {
    dispatch(setOrderId(qrCode?.orderId));
    navigate('/order-completed');
  };

  const [isPaid, setIsPaid] = useState(false);

  setTimeout(() => {
    setIsPaid(!isPaid);
  }, 5000);

  const fetchPaymentStatus = async () => {
    try {
      const response = await getOrderDetailData(qrCode?.orderId);
      if (response.data.paymentMethodStatus === 'Paid') {
        handleSuccess();
      }
    } catch (err) {
      //
    }
  };

  useEffect(() => {
    if (qrCode?.orderId) {
      fetchPaymentStatus();
    }
  }, [isPaid]);

  const generateQR = async () => {
    const svg = document.querySelector('#qr-code svg');
    if (!svg) throw new Error('Không tìm thấy QR code');

    const svgData = new XMLSerializer().serializeToString(svg);
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');

    img.src = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgData)))}`;

    return new Promise((resolve) => {
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/png'));
      };
    });
  };

  const handleSaveQR = async () => {
    try {
      const dataUrl: any = await generateQR();
      const blob = await (await fetch(dataUrl)).blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'qr-code.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      toast.success('Đã lưu QR Code vào tệp');
    } catch (error) {
      toast.error('Có lỗi xảy ra. Vui lòng thử lại.');
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title='Thanh toán bằng VIETQR'
      className='max-w-[720px]'
      modalFooter={(
        <div className='flex flex-row gap-4 justify-end'>
          <Button onClick={onRequestClose} size='large' variants='neon-fuchsia' className='max-w-[160px]'>
            Đóng
          </Button>
        </div>
      )}
    >
      <div className='t-processPaymentModal py-2'>
        <div className='top flex items-center flex-col md:flex-row justify-center gap-4 md:gap-6'>
          <div className='bg-alice-blue2 p-4 md:w-5/12 rounded-lg'>
            <div className='aspect-square  p-6 bg-white rounded-lg shadow-sm' id='qr-code'>
              <QRCode
                size={200}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={qrCode?.qrCode}
                viewBox='0 0 200 200'
              />
            </div>
            <div className='flex justify-between mt-4'>
              <p>Tổng tiền</p>
              <p className='text-neon-fuchsia font-semibold'>{formatVndCurrency(qrCode?.amount || 0)}</p>
            </div>
            <button
              onClick={handleSaveQR}
              className='w-full flex items-center justify-center gap-2 bg-neon-fuchsia text-white px-4 py-2 rounded-lg mt-4 hover:opacity-90'
            >
              <Download size={16} />
              Lưu QR Code
            </button>
          </div>
          <div className='md:w-7/12'>
            <h4 className='text-xl font-semibold text-raisin-black'>
              Quét mã QR để thanh toán
            </h4>
            {/* Info  */}
            <div className='space-y-3 mt-3'>
              {instructions.map((instruction, index) => (
                <div key={`instruction-${index.toString()}`} className='flex items-center'>
                  <div className='mr-2'>
                    <div className='bg-blueRyb text-white w-5 h-5 text-sm rounded-full inline-flex items-center justify-center'>
                      {index + 1}
                    </div>
                  </div>
                  <div className='inline-block'>
                    <span className='text-feldgrau'>{instruction}</span>
                  </div>
                </div>
              ))}
            </div>
            {/* Countdown  */}
            <div className='countdown flex flex-col items-center justify-center bg-alice-blue p-6 rounded-xl mt-4 lg:mt-6'>
              <p className='text-raisin-black mb-3'>Giao dịch sẽ kết thúc sau:</p>
              <div className='flex items-center space-x-1'>
                <div className='bg-neon-fuchsia text-white font-semibold w-7 h-7 flex items-center justify-center rounded-md'>
                  {String(displayTime.min).padStart(2, '0')}
                </div>
                <span className='text-raisin-black text-md'>:</span>
                <div className='bg-neon-fuchsia text-white font-semibold w-7 h-7 flex items-center justify-center rounded-md'>
                  {String(displayTime.seconds).padStart(2, '0')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='h-[1px] w-full bg-gainsboro my-4' />
        {/* Banks  */}
        <div className='banks'>
          <div className='panel mt-6'>
            <h5 className='text-raisin-black font-semibold'>
              Danh sách Ngân hàng/ Ví điện tử hỗ trợ thanh toán
            </h5>
            <div className='grid grid-cols-3 gap-2 mt-4 md:grid-cols-5'>
              {
                bankList.map((b, i) => (
                  <button className='item bg-anti-flash-white2 flex items-center justify-center px-3 py-2 rounded-lg' key={`bank-${i.toString()}`}>
                    <img src={b.icon} alt={b.name} />
                  </button>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ProcessPaymentModal;
