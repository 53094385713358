import React from 'react';

import HelmetSEO from 'components/templates/Helmet';
import BannerContainer from 'containers/BannerContainer';
import FeaturedProductContainer from 'containers/FeaturedProductContainer';
import HotSaleContainer from 'containers/HotSaleContainer';
import IntroCardContainer from 'containers/IntroCardContainer';
import ManufacturesSlideContainer from 'containers/ManufacturesSlideContainer';
import useLogout from 'hooks/useLogout';

const Home: React.FC = () => {
  const {
    hotSaleProducts, refetchData, hotSaleLoading, featuredProducts, featuredLoading
  } = useLogout();

  return (
    <>
      <HelmetSEO
        title='BidaHub'
        description='BidaHub là một đơn vị chuyên cung cấp các thiết bị bida chính hãng phù hợp với tất cả các người chơi'
        url='https://bidahub.com'
        image='https://bidahub.com/static/media/logo.1161657e3f26c20823a0.png'
        siteName='BidaHub'
      />
      <div className='p-home bg-page'>
        {/* Banner  */}
        <section className='p-home__banner'>
          <BannerContainer />
        </section>
        {/* Intro  */}
        <section className='p-home__intro'>
          <div className='container mx-auto'>
            <IntroCardContainer />
          </div>
        </section>
        {/* HOT SALE  */}
        <section className='p-home__hotSale'>
          <HotSaleContainer
            products={hotSaleProducts}
            loading={hotSaleLoading}
            refetchData={refetchData}
          />
        </section>
        {/* FEATURED PRODUCTS  */}
        <section className='p-home__featured lg:pt-6 pt-4 bg-alice-blue2'>
          <FeaturedProductContainer
            products={featuredProducts}
            loading={featuredLoading}
            refetchData={refetchData}
          />
        </section>
        {/* PARTNERS  */}
        <section className='p-home__partners pt-6 bg-alice-blue2'>
          <ManufacturesSlideContainer />
        </section>
      </div>
    </>
  );
};

export default Home;
