/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import deliveryImg from 'assets/images/delivery.png';
import Button from 'components/atoms/Button';
import { LoadingDelivery } from 'components/molecules/Loading';
import AddressSelector, { AddressItemType } from 'components/organisms/AddressSelector';
import ContentPanel from 'components/organisms/ContentPanel';
import CustomModal from 'components/organisms/Modal';
import TitleBackHandler from 'components/organisms/TitleBackHandler';
import { CustomerAddressFormType } from 'components/templates/CustomerAddressForm';
import UpdateAddressModal from 'components/templates/UpdateAddressModal';
import {
  addDeliveryAddressApi,
  deleteDeliveryAddressApi, editDeliveryAddressApi, getDeliveryInfoApi
} from 'services/profile';
import { useAppSelector } from 'store/hooks';
import { IParamsAddress } from 'types/users';

interface UserAddressContainerProps {
  onBack?: () => void;
}

const UserAddressContainer: React.FC<UserAddressContainerProps> = ({
  onBack
}) => {
  const [newAddress, setNewAddress] = useState(false);
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [openDeleteAddress, setOpenDeleteAddress] = useState(false);
  const [userAddress, setUserAddress] = useState<AddressItemType[]>([]);
  const [loading, setLoading] = useState(false);
  const { provinces } = useAppSelector((state) => state.general);
  const { user } = useAppSelector((state) => state.auth);

  const userId = useAppSelector((state: any) => state.auth.userId);
  const [editAddressId, setEditAddressId] = useState<number | null>(null);

  const searchDistrictName = (cityCodeName: string, districtCodeName: string) => provinces
    && provinces
      ?.find((p) => p.codeName === cityCodeName)
      ?.district?.find((d) => d.codeName === districtCodeName)?.name;

  const searchWardName = (
    cityCodeName: string,
    districtCodeName: string,
    wardCodeName: string
  ) => provinces
    && provinces
      ?.find((p) => p.codeName === cityCodeName)
      ?.district?.find((d) => d.codeName === districtCodeName)
      ?.ward?.find((w) => w.codeName === wardCodeName)?.name;

  const fetchDataDeliveryAddress = async () => {
    try {
      setLoading(true);
      const response = await getDeliveryInfoApi();
      const userInfoParses = response?.data?.addresses?.map((item) => {
        const address2 = JSON.parse(item?.address2);
        // const city = provinces?.find((p) => p.codeName === address2?.city)?.name;
        // const district = searchDistrictName(address2?.city, address2?.district);
        // const ward = searchWardName(address2?.city, address2?.district, address2?.ward);
        return {
          id: item?.id,
          name: `${item?.firstName} ${item?.lastName}`,
          // address: `${item?.address1 ? item?.address1 : item?.address2}, ${city || ''}`,
          address: `${item?.address1 ? item?.address1 : item?.address2}, ${address2.ward || ''
            }, ${address2.district || ''}, ${address2.city || ''}`,
          phone: item?.phoneNumber,
          email: item?.email,
          address2: JSON.parse(item?.address2),
          isDefault: item?.formattedCustomAddressAttributes === 'Default: Yes'
        };
      });
      console.log(userInfoParses);
      setUserAddress(userInfoParses);
      setLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };

  useEffect(() => {
    if (user) {
      fetchDataDeliveryAddress();
    }
  }, []);

  const handleFormSubmit = async (data: CustomerAddressFormType) => {
    try {
      setOpenEditAddress(false);
      if (newAddress) {
        const paramsAdd = {
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
          email: data.email || `${data?.phoneNumber}@bidahub.com`,
          address1: data.specificAddress,
          address2: JSON.stringify({
            district: data.district,
            ward: data.ward,
            city: data.city,
          })
        };
        const formValue = [
          {
            Key: 'address_attribute_1',
            Value: data?.saveAddress2 ? 1 : 0
          }
        ];
        await addDeliveryAddressApi(paramsAdd as IParamsAddress, formValue);
        toast.success('Thêm địa chỉ thành công!');
      } else {
        const paramsEdit = {
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
          email: data.email || `${data?.phoneNumber}@bidahub.com`,
          address1: data.specificAddress,
          address2: JSON.stringify({
            district: data.district,
            ward: data.ward,
            city: data.city
          }),
          id: editAddressId
        };
        const formValue = [
          {
            Key: 'address_attribute_1',
            Value: data?.saveAddress2 ? 1 : 0
          }
        ];

        await editDeliveryAddressApi(paramsEdit as IParamsAddress, editAddressId as number, formValue);
        toast.success('Cập nhật địa chỉ thành công!');
      }

      fetchDataDeliveryAddress();
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };

  const handleDeleteAddress = async (id: number) => {
    try {
      await deleteDeliveryAddressApi(id);
      fetchDataDeliveryAddress();
      setOpenDeleteAddress(false);
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };

  const dataUserEdit = useMemo(() => userAddress.filter((address: AddressItemType) => address?.id === editAddressId), [userAddress, editAddressId]);
  return (
    <>
      <TitleBackHandler onBack={onBack}>
        Địa chỉ
      </TitleBackHandler>
      {
        !userAddress || userAddress.length < 1
          ? (
            <div className='px-4 lg:px-6 py-14 bg-white rounded-lg'>
              {loading
                ? <LoadingDelivery />
                : (
                  <div className='flex items-center flex-col justify-center text-center'>
                    <div className='aspect-1 max-w-[100px]'>
                      <img src={deliveryImg} alt='none delivery address' className='w-full h-full object-cover' />
                    </div>
                    <h3 className='text-gunmetal font-semibold mt-4'>Chưa có địa chỉ</h3>
                    <p className='text-md text-gunmetal mt-2 mx-auto max-w-[250px]'>Vui lòng thêm địa chỉ để tiếp tục đặt hàng.</p>
                  </div>
                )}
              <div className='add mt-8 lg:mt-8 lg:max-w-[175px] mx-auto'>
                <Button
                  size='large'
                  onClick={() => {
                    setNewAddress(true);
                    setOpenEditAddress(true);
                  }}
                  className='bg-blueRyb text-white px-4 py-3 rounded-lg'
                >
                  Thêm địa chỉ mới
                </Button>
              </div>
            </div>
          ) : (
            <ContentPanel>
              {userAddress.length > 0 && (
                <AddressSelector
                  isNaked
                  isShowDivider
                  addresses={userAddress}
                  onDelete={() => { setOpenDeleteAddress(true); }}
                  onEdit={(id) => {
                    setEditAddressId(id);
                    setOpenEditAddress(true);
                    setNewAddress(false);
                  }}
                />
              )}
              <div className='add mt-6 lg:mt-8 lg:max-w-[185px] mx-auto'>
                <Button
                  size='large'
                  onClick={() => {
                    setNewAddress(true);
                    setOpenEditAddress(true);
                    setEditAddressId(null);
                  }}
                  className='bg-blueRyb text-white px-4 py-3 rounded-lg'
                >
                  Thêm địa chỉ mới
                </Button>
              </div>
            </ContentPanel>
          )
      }
      {/* ========================= MODAL ================================  */}
      <UpdateAddressModal
        title={newAddress ? 'Thêm địa chỉ' : 'Cập nhật địa chỉ'}
        isOpen={openEditAddress}
        onRequestClose={() => {
          setOpenEditAddress(false);
          setEditAddressId(null);
        }}
        handleSubmit={handleFormSubmit}
        userAddress={dataUserEdit?.[0]}
        isNewAddress={newAddress}
      />

      {/* Delete address popup */}
      <CustomModal
        isOpen={openDeleteAddress}
        onRequestClose={() => setOpenDeleteAddress(false)}
        title='Xóa địa chỉ'
        modalFooter={(
          <div className='flex flex-row gap-4 justify-end'>
            <Button
              onClick={() => { setOpenDeleteAddress(false); }}
              size='large'
              variants='secondary'
              className='max-w-[120px]'
            >
              Không
            </Button>
            <Button size='large' loading={loading} onClick={() => handleDeleteAddress(userId)} variants='neon-fuchsia' className='max-w-[120px]'>
              Đồng ý
            </Button>
          </div>
        )}
      >
        <p className='text-gunmetal p-6'>
          Bạn có chắc muốn xóa địa chỉ đã chọn không ?
        </p>
      </CustomModal>
    </>
  );
};

export default UserAddressContainer;
