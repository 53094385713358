import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import HelmetSEO from 'components/templates/Helmet';
import ManufacturesContainer from 'containers/ManufacturesContainer';
import { setManufacturer } from 'store/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import SLUG_PARAMS from 'utils/constants';

const Manufacturers: React.FC = () => {
  const { title, id, image } = useAppSelector((state) => state.general.manufacturer);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(setManufacturer({
      title: '',
      id: '',
      image: ''
    }));
  }, [location.pathname.includes(SLUG_PARAMS.MANUFACTURES)]);

  return (
    <>
      <HelmetSEO
        title={title ? `${title} | BidaHub` : 'BidaHub'}
        description='BidaHub là một đơn vị chuyên cung cấp các thiết bị bida chính hãng phù hợp với tất cả các người chơi'
        url={`https://bidahub.com/${SLUG_PARAMS.MANUFACTURES}/${id}`}
        image={image}
        siteName='BidaHub'
      />
      <div className='p-manufacturers bg-page'>
        <ManufacturesContainer />
      </div>
    </>
  );
};

export default Manufacturers;
