/* eslint-disable @typescript-eslint/no-unused-vars */
// src/ProductCard.tsx
import React, {
  useRef, useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SlideshowViewerRef } from '../SlideshowViewer';

import Button from 'components/atoms/Button';
import { ProductCardSkeleton } from 'components/atoms/Skeleton';
import ReceiveText from 'components/molecules/ReceiveText';
import ProductPreviewModal from 'components/templates/ProductPreviewModal';
import useCart from 'hooks/useCart';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { addToCartApi } from 'services/cart';
import { getProductDetailApi, postSubscriptionStockProductsApi, UpdatePriceWithAtrribute } from 'services/product';
import { updateFromWishList } from 'services/wishlist';
import { setLoggedIn } from 'store/auth';
import { useAppSelector } from 'store/hooks';
import { IAddToCartRequest } from 'types/cart';
import { IProduct, IProductAttributeParams } from 'types/product';
import { addToCartAnalytics } from 'utils/analytics/ecommerce';
import SLUG_PARAMS, { CART_TYPE } from 'utils/constants';
import { formatVndCurrency, parseCurrency } from 'utils/functions';

export type ProductType = {
  imageSrc: string;
  title: string;
  category: string;
  brand: string;
  price: number;
  rating: number;
  reviews: number;
  id: number;
  count?: number;
  description?: string;
  outStock?: boolean;
  maskAsNew?: boolean;
  alreadySubscribed?: boolean;
  inWishlist?: boolean
  wishlistId?: number;
};

interface ProductCardProps {
  data: ProductType;
  onLiked?: (data: ProductType) => void;
  handleNotify?: () => void;
  isLoadingFetch?: boolean;
  refetchData?: () => void;
  hideCategory?: boolean;
}
interface IAttributes {
  attributeId: string;
  value: string;
}

const ProductCard: React.FC<ProductCardProps> = ({
  data,
  onLiked,
  handleNotify,
  refetchData = () => { },
  isLoadingFetch,
  hideCategory
}) => {
  const linkToProduct = data?.id ? `${SLUG_PARAMS.PRODUCTS_DETAIL}/${data?.id}` : '';
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [buttonHovered, setButtonHovered] = useState(false);
  const [button2Hoverd, setButton2Hoverd] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<IProduct>();
  const { width } = useWindowDimensions();
  const { handleFetchShoppingCart, handleFetchWishList } = useCart();
  const slideRef = useRef<SlideshowViewerRef>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAppSelector((state) => state.auth);
  // TODO : Check product whether liked or not
  const [attributes, setAttributes] = useState<IAttributes[]>([]);

  const priceText = (() => (
    <>
      <p className='o-productCard__price text-base lg:text-xl font-semibold text-neon-fuchsia'>{formatVndCurrency(data?.price || 0)}</p>
      {/* <p className='
      o-productCard__oldPrice text-xs lg:text-lg font-semibold text-roman-silver line-through
      '
      >
        {formatVndCurrency(data?.price || 0)}
      </p> */}
    </>
  ))();

  const fetchChangeAtrribute = async (preAttribute: any) => {
    const params: IProductAttributeParams = {
      id: data?.id,
      formValues: [
        {
          key: 'loadPicture',
          value: 'True'
        }
      ]
    };

    preAttribute?.forEach((attribute: any) => {
      params.formValues.push({
        key: `product_attribute_${attribute.attributeId}`,
        value: attribute.value,
      });
    });

    const response = await UpdatePriceWithAtrribute(params);
    setProduct((prev: any) => ({
      ...prev,
      productPrice: {
        priceValue: response.data.price?.replace(/[.\s₫]/g, '') || 0
      },
      sku: response.data.sku || '',
      isFreeShipping: response.data.isFreeShipping,
      stockAvailability: response.data.stockAvailability
    }));

    const indexSlide: number = product?.pictureModels.findIndex(
      (item) => item.imageUrl === response.data.pictureDefaultSizeUrl
    ) || 0;

    if (response.data.pictureDefaultSizeUrl) {
      slideRef.current?.goToSlide(indexSlide);
    }
    return response;
  };

  const onNotify = async (id: number) => {
    handleNotify?.();
    try {
      await postSubscriptionStockProductsApi(Number(id));
      if (data?.alreadySubscribed) {
        toast.success('Huỷ đăng ký nhận thông báo thành công!');
      } else {
        toast.success('Đăng ký nhận thông báo thành công!');
      }
      refetchData();
    } catch (error: any) {
      if (!user) {
        dispatch(setLoggedIn(true));
        toast.error('Vui lòng đăng nhập để đăng ký nhận thông báo');
      } else {
        toast.error(user?.email ? error?.response?.data?.ErrorList?.[0] : 'Cập nhật email ở trang tài khoản để nhận thông báo có hàng từ hệ thống');
      }
    }
  };
  const handleAddToCart = async (params: IAddToCartRequest, type?: number) => {
    try {
      if (type === 1) {
        setLoading(true);
        addToCartAnalytics({
          id: data?.id,
          name: data?.title,
          price: data?.price,
          quantity: 1,
        });
      }
      await addToCartApi(params);
      toast.success(type === 1 ? 'Sản phẩm đã được thêm vào giỏ hàng' : 'Sản phẩm đã được thêm vào danh sách yêu thích');
      setLoading(false);
      if (type === 1) {
        handleFetchShoppingCart();
      } else {
        handleFetchWishList();
        handleFetchShoppingCart();
      }
      refetchData();
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleRemoveProductFromWishList = async (id: number) => {
    try {
      const params = {
        formValues: [
          {
            key: 'removefromcart',
            value: `${id}`
          }
        ],
        message: '',
      };

      await updateFromWishList(params);
      refetchData();
      handleFetchWishList();
      handleFetchShoppingCart();
      toast.success('Đã xoá sản phẩm khỏi danh sách yêu thích');
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleCheckProduct = async (type?: string, cartType?: number) => {
    try {
      const response = await getProductDetailApi(data?.id);
      const productData = response.data;
      const defaultAttributes = productData.productAttributes?.map((attr) => {
        const firstValue = attr?.values?.[0];
        return firstValue ? { attributeId: attr.id, value: firstValue.id } : null;
      })?.filter(Boolean) || [];

      let updatedPrice = productData.productPrice?.priceValue;
      let updateStockAvailability = productData?.stockAvailability;
      if (defaultAttributes.length > 0) {
        const attributeResponse: any = await fetchChangeAtrribute(defaultAttributes);
        updatedPrice = parseCurrency(attributeResponse?.data?.price) || updatedPrice || 0;
        updateStockAvailability = attributeResponse?.data?.stockAvailability;
      }
      setProduct({
        ...productData,
        productPrice: {
          ...productData.productPrice,
          priceValue: updatedPrice,
        },
        stockAvailability: updateStockAvailability
      });
      setAttributes(defaultAttributes as []);
      if (response.data?.productAttributes.length <= 0) {
        await handleAddToCart({
          productId: data?.id,
          type: cartType as number,
          data: {
            FormValues: [{
              Key: `addtocart_${data?.id}.EnteredQuantity`,
              Value: type as string
            }]
          }
        }, cartType);
      } else if (width < 1024) {
        navigate(linkToProduct);
      } else {
        setOpenPreviewModal(true);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleFetchProductDetail = async () => {
    try {
      const response = await getProductDetailApi(data?.id);
      const productData = response.data;
      const defaultAttributes = productData.productAttributes?.map((attr) => {
        const firstValue = attr?.values?.[0];
        return firstValue ? { attributeId: attr.id, value: firstValue.id } : null;
      })?.filter(Boolean) || [];

      let updatedPrice = productData.productPrice?.priceValue;
      let updateStockAvailability = productData?.stockAvailability;
      if (defaultAttributes.length > 0) {
        const attributeResponse: any = await fetchChangeAtrribute(defaultAttributes);
        updatedPrice = parseCurrency(attributeResponse?.data?.price) || updatedPrice || 0;
        updateStockAvailability = attributeResponse?.data?.stockAvailability;
      }
      setProduct({
        ...productData,
        productPrice: {
          ...productData.productPrice,
          priceValue: updatedPrice,
        },
        stockAvailability: updateStockAvailability
      });
      setAttributes(defaultAttributes as []);
      setOpenPreviewModal(true);
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  if (isLoadingFetch) {
    return <ProductCardSkeleton />;
  }

  const onHandleLike = (dt: ProductType) => {
    onLiked?.(dt);
    handleCheckProduct('1', CART_TYPE.WISHLIST);
    // setLiked(!liked);
  };

  return (
    <>
      <div className='o-productCard rounded-xl hover:shadow-md p-2 sm:p-3 bg-white base-transition'>
        <div
          className='relative group'
        >
          <div className='o-productCard__image w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden'>
            <Link to={linkToProduct} title={data?.title}>
              <img
                src={data?.imageSrc}
                alt={data?.title}
                className='object-contain w-full h-full rounded-lg transform transition duration-500 hover:scale-110'
              />
            </Link>
          </div>
          {data?.outStock && (
            <div className='py-1 px-2 bg-raisin-black absolute left-0 top-0 rounded text-white text-md font-normal'>
              Hết hàng
            </div>
          )}
          <div className='interaction absolute top-2 right-1 xl:top-3 xl:right-3 w-auto h-auto xl:hidden xl:group-hover:block base-transition'>
            <button
              onMouseEnter={() => width >= 1024 && setButtonHovered(true)}
              onMouseLeave={() => width >= 1024 && setButtonHovered(false)}
              type='button'
              onClick={() => (data?.inWishlist
                ? handleRemoveProductFromWishList(data?.wishlistId as number)
                : onHandleLike(data))}
              className='btnHeart w-8 h-8 xl:w-12 xl:h-12 border border-gainsboro rounded-lg bg-white flex items-center justify-center base-transition group'
            >
              <svg className='text-gunmetal w-4 h-4 xl:w-6 xl:h-6' width='24' height='24' viewBox='0 0 21 20' fill={`${buttonHovered || data?.inWishlist ? '#F5416C' : '#ffffff'}`} xmlns='http://www.w3.org/2000/svg'>
                <path d='M18.0416 8.02967C18.0333 5.91645 16.9246 3.92911 14.7388 3.22496C13.2379 2.74062 11.603 3.00997 10.3333 4.83286C9.06363 3.00997 7.42873 2.74062 5.92783 3.22496C3.74179 3.92919 2.63309 5.9169 2.62507 8.0304C2.60485 12.2335 6.86385 15.4498 10.3323 16.9871L10.3333 16.9866L10.3344 16.9871C13.803 15.4497 18.0623 12.2331 18.0416 8.02967Z' stroke={`${buttonHovered || data?.inWishlist ? '#F5416C' : '#2E3538'}`} strokeWidth='1.5' strokeLinecap='square' />
              </svg>
            </button>
            <button
              onMouseEnter={() => width >= 1024 && setButton2Hoverd(true)}
              onMouseLeave={() => width >= 1024 && setButton2Hoverd(false)}
              type='button'
              onClick={handleFetchProductDetail}
              className='btnEye hidden xl:flex mt-2 w-8 h-8 xl:w-12 xl:h-12 border border-gainsboro rounded-lg bg-white items-center justify-center  xl:hover:bg-neon-fuchsia base-transition xl:hover:border-neon-fuchsia group'
            >
              <svg className='text-gunmetal w-4 h-4 xl:w-6 xl:h-6' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M15.5799 12C15.5799 13.98 13.9799 15.58 11.9999 15.58C10.0199 15.58 8.41992 13.98 8.41992 12C8.41992 10.02 10.0199 8.42004 11.9999 8.42004C13.9799 8.42004 15.5799 10.02 15.5799 12Z' stroke={`${button2Hoverd ? 'white' : '#2E3538'}`} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z' stroke={`${button2Hoverd ? 'white' : '#2E3538'}`} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
              </svg>

            </button>
          </div>
        </div>
        <div className='mt-2 sm:mt-3'>
          <Link to={linkToProduct} title={data?.title}>
            <h3 className='o-productCard__title font-semibold line-clamp-2'>
              {data?.title}
            </h3>
          </Link>
          <div className='mt-2 md:mt-3'>
            <ReceiveText bcoin={data?.price || 0} />
          </div>
          {
            data?.category && !hideCategory
            && (
              <div className='flex mt-2 sm:mt-3 justify-between'>
                <p className='o-productCard__category hidden sm:block font-medium text-sm text-feldgrau'>{data?.category}</p>
                <p className='o-productCard__brand font-medium text-sm text-feldgrau'>{data?.brand}</p>
              </div>
            )
          }
          <div className='my-2 border-t hidden sm:block border-gainsboro w-full' />
          <Link to={linkToProduct} className='hidden sm:block'>
            {priceText}
          </Link>
          {/* <div className='flex items-center mt-1 mb-2 sm:mt-2 sm:mb-3'>
            <StarRating isStatic count={5} value={data?.rating} isResponsive onChange={() => { }} />
            <span className='rating-text text-sm lg:text-md text-feldgrau ml-1 2xl:ml-2'>
            ({data?.reviews} đánh giá)</span>
          </div> */}
          <div className='flex items-center justify-between'>
            <Link to={linkToProduct} className='block sm:hidden'>
              {priceText}
            </Link>
            {!data?.outStock ? (
              // Add icon
              <div onClick={() => handleCheckProduct('1', CART_TYPE.CART)} className='flex-none w-8 h-w-8 block sm:hidden ml-2'>
                <svg className='w-8 h-w-8 text-neon-fuchsia' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 24 24'>
                  <path fillRule='evenodd' d='M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z' clipRule='evenodd' />
                </svg>
              </div>
            ) : (
              // notify icon
              <button type='button' className='flex-none w-8 h-w-8 block sm:hidden ml-2' onClick={() => onNotify(data?.id)}>
                {
                  !data?.alreadySubscribed ? (
                    <svg className='w-8 h-w-8' width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <rect x='0.5' y='0.500122' width='32' height='32' rx='16' fill='#0D47F8' />
                      <path d='M12 15.0001C12 12.5148 14.0147 10.5001 16.5 10.5001C18.9853 10.5001 21 12.5148 21 15.0001V16.9038L21.9642 19.3144C22.0259 19.4685 22.0071 19.6431 21.914 19.7804C21.821 19.9178 21.6659 20.0001 21.5 20.0001H11.5C11.3341 20.0001 11.179 19.9178 11.086 19.7804C10.993 19.643 10.9741 19.4685 11.0358 19.3144L12 16.9038V15.0001ZM14.5625 21.0001C14.7833 21.8648 15.5644 22.5001 16.5 22.5001C17.4356 22.5001 18.2167 21.8648 18.4375 21.0001H14.5625Z' fill='white' />
                    </svg>
                  ) : (
                    <svg className='w-8 h-w-8' width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <rect x='1' y='0.500244' width='31' height='31' rx='15.5' fill='white' stroke='#3D8EFF' />
                      <path d='M12 14.5002C12 12.015 14.0147 10.0002 16.5 10.0002C18.9853 10.0002 21 12.015 21 14.5002V16.404L21.9642 18.8145C22.0259 18.9686 22.0071 19.1432 21.914 19.2806C21.821 19.4179 21.6659 19.5002 21.5 19.5002H11.5C11.3341 19.5002 11.179 19.4179 11.086 19.2806C10.993 19.1432 10.9741 18.9686 11.0358 18.8145L12 16.404V14.5002ZM14.5625 20.5002C14.7833 21.3649 15.5644 22.0002 16.5 22.0002C17.4356 22.0002 18.2167 21.3649 18.4375 20.5002H14.5625Z' fill='#2E3538' />
                    </svg>
                  )

                }

              </button>
            )}
          </div>
          {!data?.outStock && (
            <div className='mt-3'>
              <Button loading={loading} icon='cart' onClick={() => handleCheckProduct('1', CART_TYPE.CART)} className='hidden sm:flex' variants='neon-fuchsia' size='large'>
                Thêm vào giỏ
              </Button>
            </div>
          )}

          {data?.outStock && (
            !data?.alreadySubscribed ? (
              <Button
                size='large'
                className='hidden sm:flex mt-3'
                onClick={() => onNotify(data?.id)}
              >
                Nhận Thông Báo
              </Button>
            ) : (
              <Button
                size='large'
                variants='secondary'
                className='hidden sm:flex mt-3'
                onClick={() => onNotify(data?.id)}
              >
                Hủy Đăng Ký
              </Button>
            )
          )}
        </div>
      </div>
      {/* PREVIEW MODAL  */}
      <ProductPreviewModal
        data={{ ...product, ...data } as ProductType & IProduct}
        isOpen={openPreviewModal && width >= 1024}
        onClose={() => setOpenPreviewModal(false)}
        onAddCart={(params: IAddToCartRequest, type: number) => {
          handleAddToCart(params, type);
          setOpenPreviewModal(false);
        }}
        onReceiveNoti={onNotify}
        fetchChangeAtrribute={fetchChangeAtrribute}
        sliderRef2={slideRef as any}
        attributes={attributes}
        setAttributes={setAttributes}
        onRemoveProductFromWishList={handleRemoveProductFromWishList}
      />
    </>
  );
};

export default ProductCard;
