/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { is } from 'ramda';
import { toast } from 'react-toastify';

import { IShoppingCartOrderTotals } from '../types/cart';

import { IShoppingCartItem } from 'types/cart';
import { IParamsGetCategoriesIds } from 'types/catalog';
import { IProduct } from 'types/search';

function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      []
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSection(ref: React.RefObject<HTMLDivElement>) {
  if (ref && ref.current) {
    window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - 68 }); // Minus header height
  }
}

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX =
      evt.clientX +
      document.body.scrollLeft +
      document.documentElement.scrollLeft;
    pageY =
      evt.clientY +
      document.body.scrollTop +
      document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false
  );
}

// export const formatVndCurrency = (amount: number) => {
//   // Convert the number to a string and split it into integer and decimal parts
//   const integer = amount.toString().split('.')[0];

//   // Add commas to the integer part
//   const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

//   // Combine the integer and decimal parts with the currency symbol
//   return `${formattedInteger}đ`;
// };

/**
 * Formats a number as a Vietnamese currency string.
 *
 * @param {number} price - The number to format.
 * @return {string} The formatted Vietnamese currency string.
 */
export const formatVndCurrency = (price: number): string =>
  price?.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });

export const convertCurrencyStringToNumber = (currencyString: string) => {
  // Remove the currency symbol and any non-numeric characters except for the decimal separator
  const numericString = currencyString.replace(/[^\d,]/g, '').replace(',', '');
  return parseInt(numericString, 10);
};

export const parseCurrency = (value: string) =>
  parseFloat(value?.replace(/\./g, '')?.replace(/[^\d.-]/g, ''));
/**
 * Converts a timestamp to a human-readable time ago string.
 *
 * @param {string} timestamp - The timestamp to convert.
 * @return {string} The time ago string in the format "X unit(s) ago" or "just now".
 */
export const convertTimeAgo = (timestamp: string): string => {
  const now = new Date();
  const past = new Date(timestamp);
  const seconds = Math.floor((now.getTime() - past.getTime()) / 1000);

  const intervals: { [key: string]: number } = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (const [unit, value] of Object.entries(intervals)) {
    const count = Math.floor(seconds / value);
    if (count >= 1) {
      return `${count} ${unit}${count > 1 ? 's' : ''} ago`;
    }
  }

  return 'just now';
};

export const detectEmailOrPhone = (input: string) => {
  // Regular expression for validating an Email
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Regular expression for validating a Phone Number
  // This example assumes phone numbers are digits only, with or without country code
  // Modify the regex to suit your specific phone number formats (e.g., dashes, spaces, etc.)
  const phoneRegex = /^\+?[0-9]{10,15}$/;

  if (emailRegex.test(input)) {
    return 'email';
  }
  if (phoneRegex.test(input)) {
    return 'phone';
  }
  return 'invalid';
};
export const formatDecimalNumber = (num: string | number) => {
  let value;
  if (typeof num === 'number') value = num.toString();
  else {
    value = num;
  }
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
export const formatDecimalNumberWithDot = (num: string | number) => {
  let value;
  if (typeof num === 'number') value = num.toString();
  else {
    value = num;
  }
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

/**
 * Formats a given datetime string into a string representing the time in UTC hours and minutes,
 * and the date in UTC day, month, and year.
 *
 * @param {string} datetimeString - The datetime string to format.
 * @return {string} The formatted datetime string in the format "HH:mm DD/MM/YYYY".
 */
export const formatDate = (datetimeString: string): string => {
  const date = new Date(datetimeString);

  // Extract the components
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getUTCFullYear();

  // Return in the desired format
  return `${hours}:${minutes} ${day}/${month}/${year}`;
};
/**
 * Formats a given date string into a string representing the date in the format:
 * day/month/year hour:minute AM/PM.
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted date string dd/mm/yyyy h:mm AM/PM
 */
export const formatDateDDMMYYYYHMM = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

/**
 * Formats a given ISO date string into a string representing the date in the format:
 * day/month/year hour:minute.
 *
 * @param {string} isoDate - The ISO date string to format.
 * @returns {string} The formatted date string dd/mm/yyyy HH:mm
 */
export const formatDateDDMMYYYYHHMM = (isoDate: string): string => {
  const date = new Date(isoDate);

  const day = String(date.getDate()).padStart(2, '0'); // Get day and ensure it's two digits
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

/**
 * Formats a given ISO date string into a string representing the date in the format:
 * day/month/year hour:minute.
 *
 * @param {string} isoDate - The ISO date string to format.
 * @returns {string} The formatted date string dd/mm/yyyy
 */
export const formatDateDDMMYYYY = (isoDate: string): string => {
  const date = new Date(isoDate);

  const day = String(date.getDate()).padStart(2, '0'); // Get day and ensure it's two digits
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const getRandomNumber = (): number => {
  const numbers = [1, 2, 3];
  const randomIndex = Math.floor(Math.random() * numbers.length);
  return numbers[randomIndex];
};

export const handleScrollCenter = (
  classNameEleSroll: string,
  classNameEleActive: string
) => {
  const eleSroll = document.querySelector(classNameEleSroll);
  const eleActive = document.querySelector(classNameEleActive);
  if (!eleActive || !eleSroll) return;
  // get width element scroll
  const widthEleSroll = eleSroll.getBoundingClientRect().width;
  // get distance element scroll compared to y window
  const xEleScroll = eleSroll.getBoundingClientRect().x;
  // get width element active
  const widthEleActive = eleActive.getBoundingClientRect().width;
  // get distance element active compared to y window
  const xEleActive = eleActive.getBoundingClientRect().x;
  // get position sroll bar
  const positionSroll = eleSroll.scrollLeft;
  const scrollX =
    xEleActive -
    xEleScroll +
    widthEleActive / 2 +
    positionSroll -
    widthEleSroll / 2;
  eleSroll.scroll({
    left: scrollX,
    behavior: 'smooth',
  });
};

function camelize(str: string): string {
  return str?.replace(/^([A-Z])|[\s-_/]+(\w)/g, (_match, p1, p2) => {
    if (p2) {
      return p2.toUpperCase();
    }

    return p1.toLowerCase();
  });
}

export function camelizeKeys(data: any): any {
  if (is(Date, data) || !is(Object, data)) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((datum) => camelizeKeys(datum));
  }

  return Object.entries(data).reduce((result, [k, v]) => {
    let value;

    if (is(Date, v) || !is(Object, v)) {
      value = v;
    } else {
      value = camelizeKeys(v);
    }
    Object.assign(result, {
      [camelize(k)]: value,
    });

    return result;
  }, {});
}

export function convertDayToDD(day: number): string {
  return day.toString().padStart(2, '0');
}

export function parseOrderTotal(
  data: IShoppingCartItem[],
  orderTotals: IShoppingCartOrderTotals
): IShoppingCartOrderTotals {
  // const totalDiscountFromItem = data?.reduce(
  //   (accumulator, currentValue) => accumulator + currentValue.discountValue,
  //   0
  // );
  const redeemedRewardPoints = orderTotals?.redeemedRewardPoints || 0;
  let orderDiscount = 0;
  if (orderTotals?.subTotalDiscount) {
    orderDiscount = Number(
      orderTotals?.subTotalDiscount?.toString().replace(/[.\s₫]/g, '')
    );
  }
  if (orderTotals?.orderTotalDiscount) {
    orderDiscount = Number(
      orderTotals?.orderTotalDiscount?.replace(/[.\s₫]/g, '')
    );
  }
  return {
    ...orderTotals,
    //     subTotal:
    // formatVndCurrency(Number(orderTotals?.subTotal?.replace(/[.\s₫]/g, ''))
    //       + totalDiscountFromItem),
    orderTotalDiscount: formatVndCurrency(orderDiscount - redeemedRewardPoints),
    subTotalDiscount: orderDiscount,
  };
}

export function parseProductsToCart(products: IProduct[]) {
  const parseProducts = products?.map((product) => ({
    imageSrc: product?.defaultPictureModel?.imageUrl,
    title: product?.name,
    category: '',
    brand: '',
    price: product?.productPrice?.priceValue,
    rating: product?.reviewOverviewModel?.ratingSum,
    reviews: product?.reviewOverviewModel?.totalReviews,
    id: product.id,
    count: 3,
    description: product?.shortDescription,
    outStock: !product?.inStock,
    alreadySubscribed: product?.alreadySubscribed,
    inWishlist: product?.inWishlist,
    wishlistId: product?.wishlistId,
  }));
  return parseProducts;
}

export function decodeHtmlEntities(str: any) {
  const tempElement = document.createElement('textarea');
  tempElement.innerHTML = str;
  return tempElement.value;
}

export function parseAtributeInfoValue(
  attributeInfo: string,
  options: any,
  index: number
) {
  const decodedPreInfo = decodeHtmlEntities(attributeInfo);
  const attributeValuePairs = decodedPreInfo
    .split('<br />')
    .map((pair) => pair.split(': ').map((str) => str.trim()));
  const cleanedAttributeValuePairs = attributeValuePairs.map(
    ([attributeName, valueName]) => {
      const cleanedValueName = valueName.replace(/\s*\[.*?\]\s*/g, '');
      return [attributeName, cleanedValueName];
    }
  );
  const idx = options.findIndex(
    (option: any) =>
      cleanedAttributeValuePairs?.[index]?.[1] === option?.label.trim()
  );
  return idx;
}

export function parseAttributes(attributeString: any, productAttributes: any) {
  const decodedPreInfo = decodeHtmlEntities(attributeString);
  // Step 2: Split the decoded string into an array of attribute-value pairs
  const attributeValuePairs = decodedPreInfo
    ?.split('<br />')
    ?.map((pair) => pair?.split(': ')?.map((str) => str?.trim()));
  const cleanedAttributeValuePairs = attributeValuePairs.map(
    ([attributeName, valueName]) => {
      const cleanedValueName = valueName?.replace(/\s*\[.*?\]\s*/g, '');
      return [attributeName, cleanedValueName];
    }
  );
  const result = cleanedAttributeValuePairs
    ?.map(([attributeName, valueName]) => {
      for (const attribute of productAttributes) {
        if (attribute?.name === attributeName) {
          for (const value of attribute.values) {
            if (value?.name?.trim() === valueName?.trim()) {
              return {
                attributeId: attribute?.id,
                value: value?.id,
              };
            }
          }
        }
      }
      return null;
    })
    ?.filter((item) => item !== null);
  return result || [];
}
// GENERATING CODE VERIFIER
export function dec2hex(dec: any) {
  return `0${dec.toString(16)}`.substr(-2);
}

export function generateCodeVerifier() {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join('');
}

export function sha256(plain: any) {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

export function base64urlencode(a: any) {
  let str = '';
  const bytes = new Uint8Array(a);
  const len = bytes.byteLength;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export async function generateCodeChallengeFromVerifier(v: any) {
  const hashed = await sha256(v);
  const base64encoded = base64urlencode(hashed);
  return base64encoded;
}

export const handleCopyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(
    () => {
      toast.success('Copied to clipboard');
    },
    (err) => {
      console.error('Failed to copy: ', err);
    }
  );
};

export const toQueryString = (params: IParamsGetCategoriesIds) => {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key as keyof IParamsGetCategoriesIds];
      if (Array.isArray(value)) {
        return value
          .map((val) => `${key}=${encodeURIComponent(val)}`)
          .join('&');
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');
  return queryString;
};

export const calculatePoint = (point: number) => {
  const result = formatDecimalNumberWithDot(Math.trunc(point / 100000) * 5000);
  return result;
};
