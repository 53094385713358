import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import HelmetSEO from 'components/templates/Helmet';
import CategoryContainer from 'containers/CategoryContainer';
import { setCategory } from 'store/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import SLUG_PARAMS from 'utils/constants';

const CategoryDetail: React.FC = () => {
  const location = useLocation();
  const { title, id } = useAppSelector((state) => state.general.category);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCategory({
      title: '',
      id: ''
    }));
  }, [location.pathname.includes(SLUG_PARAMS.CATEGORY_DETAIL)]);

  return (
    <>
      <HelmetSEO
        title={title ? `${title} | BidaHub` : 'BidaHub'}
        description='BidaHub là một đơn vị chuyên cung cấp các thiết bị bida chính hãng phù hợp với tất cả các người chơi'
        url={`https://bidahub.com/${SLUG_PARAMS.CATEGORY_DETAIL}/${id}`}
        image='https://bidahub.com/static/media/logo.1161657e3f26c20823a0.png'
        siteName='BidaHub'
      />
      <div className='p-categoryDetail bg-page'>
        <CategoryContainer />
      </div>
    </>
  );
};

export default CategoryDetail;
