/* eslint-disable import/prefer-default-export */
import { logEvent } from 'firebase/analytics';

import { analytics } from 'utils/firebaseConfig';

export const viewItemAnalytics = (params: any) => {
  logEvent(analytics, 'view_item', {
    app: 'Web app',
    ...params,
  });
};

export const addToCartAnalytics = (params: any) => {
  logEvent(analytics, 'add_to_cart', {
    app: 'Web app',
    ...params,
  });
};

export const removeFromCartAnalytics = (params: any) => {
  logEvent(analytics, 'remove_from_cart', {
    app: 'Web app',
    ...params,
  });
};

export const beginCheckoutAnalytics = (params: any) => {
  logEvent(analytics, 'begin_checkout', {
    app: 'Web app',
    ...params,
  });
};

export const purchaseAnalytics = (params: any) => {
  logEvent(analytics, 'purchase', {
    app: 'Web app',
    ...params,
  });
};

export const reOrderAnalytics = (params: any) => {
  try {
    logEvent(analytics, 're_order', {
      app: 'Web app',
      ...params,
    });
  } catch (e) {
    console.log(e);
  }
};

export const searchProductAnalytics = (params: any) => {
  try {
    logEvent(analytics, 'search_product', {
      app: 'Web app',
      ...params,
    });
  } catch (e) {
    console.log(e);
  }
};

export const applyCouponAnalytics = (params: any) => {
  try {
    logEvent(analytics, 'apply_coupon', {
      app: 'Web app',
      ...params,
    });
  } catch (e) {
    console.log(e);
  }
};

export const applyBcoinAnalytics = (params: any) => {
  try {
    logEvent(analytics, 'apply_bcoin', {
      app: 'Web app',
      ...params,
    });
  } catch (e) {
    console.log(e);
  }
};
