/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PaymentOptionsType } from 'components/organisms/PaymentMethodSelection';
import { CartContext } from 'providers/cartProviders';
import {
  createVietQRPaymentUrl,
  createVnpayPaymentUrl,
} from 'services/checkout';
import { reOrderApi } from 'services/order';
import { setIsRepayment, setOrderId, setRepaymentData } from 'store/cart';
import { setLoadingReducer } from 'store/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { purchaseAnalytics, reOrderAnalytics } from 'utils/analytics/ecommerce';
import SLUG_PARAMS from 'utils/constants';

const useCart = (
  paymentMethod?: PaymentOptionsType,
  onPaymentInfoChange?: any
  // isMobile?: boolean
) => {
  const context = useContext(CartContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isBanking, setIsBanking] = useState(false);
  const { repaymentData } = useAppSelector((state) => state.cart);

  if (!context) {
    throw new Error('useCart must be used within an CartProvider');
  }
  const handleReOrder = async (id: string) => {
    try {
      dispatch(setRepaymentData(null));
      dispatch(setIsRepayment(false));
      await reOrderApi(id);
      await context.handleFetchShoppingCart();
      navigate(SLUG_PARAMS.CART);
      reOrderAnalytics({ orderId: id });
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleRepayment = async (orderId?: number, amount?: number) => {
    try {
      if (paymentMethod?.id === 'banking') {
        setIsBanking(true);
      } else if (paymentMethod?.id === 'vietqr') {
        const res = await createVietQRPaymentUrl(orderId, amount);
        // if (isMobile) {
        onPaymentInfoChange({
          qrCode: res.qrCode,
          amount: Number(res.amount),
          orderId,
        });
        // } else {
        //   window.open(res.qrLink, '_self');
        // }
      } else {
        dispatch(setLoadingReducer(true));
        const vnpayResponse: any = await createVnpayPaymentUrl({
          OrderId: orderId as number,
        });
        dispatch(setLoadingReducer(false));
        window.open(vnpayResponse.url, '_self');
      }
      purchaseAnalytics({
        action: 'RePayment',
        total: amount,
        orderId,
      });
    } catch (error: any) {
      setIsBanking(false);
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const onBankingSuccess = () => {
    dispatch(setOrderId(repaymentData?.customOrderNumber));
    navigate('/order-completed');
    setIsBanking(false);
  };

  return {
    ...context,
    handleReOrder,
    handleRepayment,
    setIsBanking,
    isBanking,
    onBankingSuccess,
  };
};

export default useCart;
