/* eslint-disable @typescript-eslint/no-unused-vars */
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import ProductTable from 'components/templates/ProductTable';
import { ConfirmModal } from 'containers/ModalContainer';
import useCart from 'hooks/useCart';
import { useDeleteTableProduct } from 'hooks/useDelelteTableProduct';
import { moveWishListToCart, updateFromWishList } from 'services/wishlist';
import { useAppSelector } from 'store/hooks';
import { IWishListParams } from 'types/wishlist';
import { addToCartAnalytics } from 'utils/analytics/ecommerce';

const WishlistContainer: React.FC = () => {
  const [selectedQuantity, setSelectedQuantity] = useState(4);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const { handleFetchShoppingCart, handleFetchWishList } = useCart();
  const { openConfirmModal, handleConfirm } = useDeleteTableProduct();
  const wishlistData = useAppSelector((state) => state.wishlist.data);

  const handleQuantityChange = (newQuantity: number) => {
    setSelectedQuantity(newQuantity);
  };

  const handleAddToCart = async (params: IWishListParams) => {
    try {
      setLoading(true);
      await moveWishListToCart(params);
      setLoading(false);
      setSelectedItems([]);
      toast.success('Thêm vào giỏ hàng thành công!');
      addToCartAnalytics({
        action: 'Add to cart from wishlist',
      });
    } catch (error: any) {
      setLoading(false);
      toast.error('Sản phẩm này đã hết hàng!');
    }
  };

  const handleCheckProduct = async () => {
    try {
      const promiseAddCart: any[] = [];
      for (const id of selectedItems) {
        const addCart = handleAddToCart({
          formValues: [
            {
              key: 'addtocart',
              value: `${id}`
            },
          ],
          message: '',
        });

        promiseAddCart.push(addCart);
      }

      await Promise.allSettled(promiseAddCart);
      handleFetchShoppingCart();
      handleFetchWishList();
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleRemoveProductFromWishList = async (id: number) => {
    try {
      const params = {
        formValues: [
          {
            key: 'removefromcart',
            value: `${id}`
          }
        ],
        message: '',
      };
      await updateFromWishList(params);
      handleFetchWishList();
      handleFetchShoppingCart();
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  if (wishlistData.length === 0) return <h3 className='text-center text-lg font-semibold p-8'>Bạn chưa có sản phẩm yêu thích nào</h3>;

  return (
    <>
      <ProductTable
        products={wishlistData}
        isWishList
        hideLike
        selectItem={selectedItems}
        handleSelectItem={setSelectedItems}
        onDelete={handleRemoveProductFromWishList}
        onQuantityChange={handleQuantityChange as any}
      />
      <div className='submit mt-4 flex justify-center lg:justify-start'>
        <Button
          type='button'
          disabled={selectedItems.length === 0}
          variants='primary'
          size='large'
          className='w-full md:max-w-[201px]'
          onClick={handleCheckProduct}
        >
          Thêm vào giỏ hàng
        </Button>
      </div>
      {/* Delete address popup */}
      <ConfirmModal onConfirm={handleConfirm} />
    </>
  );
};

export default WishlistContainer;
