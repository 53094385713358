import React from 'react';
import { Helmet } from 'react-helmet-async';

interface HelmetSEOProps {
  title: string;
  description: string;
  name?: string;
  url: string;
  image: string;
  siteName: string;
  twitterHandle?: string;
  canonicalUrl?: string;
}

const HelmetSEO: React.FC<HelmetSEOProps> = ({
  title,
  description,
  name,
  url,
  image,
  siteName,
  twitterHandle,
  canonicalUrl,
}) => (
  <Helmet>
    {/* Charset */}
    <meta charSet='UTF-8' />

    {/* Viewport */}
    <meta name='viewport' content='width=device-width, initial-scale=1' />

    {/* Title and Description */}
    <title>{title}</title>
    <meta name='description' content={description} />

    {/* Canonical URL */}
    {canonicalUrl && <link rel='canonical' href={canonicalUrl} />}

    {/* Open Graph */}
    <meta property='og:type' content='website' />
    <meta property='og:title' content={title} />
    <meta property='og:description' content={description} />
    <meta property='og:url' content={url} />
    <meta property='og:image' content={image} />
    <meta property='og:site_name' content={siteName} />

    {/* Twitter */}
    <meta name='twitter:card' content='summary_large_image' />
    {name && <meta name='twitter:creator' content={name} />}
    {twitterHandle && <meta name='twitter:site' content={twitterHandle} />}
    <meta name='twitter:title' content={title} />
    <meta name='twitter:description' content={description} />
    <meta name='twitter:image' content={image} />

    {/* Robots */}
    <meta name='robots' content='index, follow' />
  </Helmet>
);

export default HelmetSEO;
