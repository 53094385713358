/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useEffect, useState } from 'react';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import PointRange from 'components/organisms/PointRange';
import { setBcoinToUse } from 'store/cart';
import { useAppDispatch } from 'store/hooks';
import { IShoppingCartOrderTotals } from 'types/cart';
import { formatDecimalNumber, formatVndCurrency, parseCurrency } from 'utils/functions';

interface PointRewardProps {
  points: number;
  appliedBcoin?: number;
  applyBCoin?: (value: number) => void;
  orderTotal?: IShoppingCartOrderTotals;
  isRepayment?: boolean
}

const stripFormatting = (num: string) => num.replace(/,/g, '');
const formatNumber = (number: string) => number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const PointReward: React.FC<PointRewardProps> = ({
  points, appliedBcoin = 0, applyBCoin = () => { }, orderTotal, isRepayment
}) => {
  const [pointValue, setPointValue] = useState(formatNumber(appliedBcoin.toString()));
  const [percent, setPercent] = useState(0);
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  // const [checkAll, setCheckAll] = useState(false);

  const point = parseCurrency(orderTotal?.subTotal as string) + Number(orderTotal?.subTotalDiscount);
  const TotalPoint = points >= point ? point : points;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    const input = stripFormatting(e.target.value);
    // Ensure it's a valid number and parse it
    const numericValue = parseFloat(input);
    if (!isNaN(numericValue)) {
      if (numericValue > TotalPoint) {
        setPointValue(formatNumber(TotalPoint.toString()));
      } else {
        setPointValue(formatNumber(input));
      }
    } else {
      setPointValue('');
    }
  };
  const handleClear = () => {
    setPointValue('');
    applyBCoin(0);
    dispatch(setBcoinToUse(0));
  };
  const submitPoint = () => {
    const numericValue = parseFloat(stripFormatting(pointValue));
    if (numericValue >= 1000) {
      applyBCoin(numericValue);
      dispatch(setBcoinToUse(numericValue));
    } else {
      setError('Nhập tối thiểu 1000 điểm.');
    }
  };

  const handlePercentChange = (val: number) => {
    setError('');
    if (val === 100) {
      setPointValue(formatNumber(TotalPoint.toString()));
    } else {
      const value = (TotalPoint * val) / 100;
      setPointValue(formatNumber(value.toString()));
    }
  };

  // const handleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
  //   setCheckAll(e.currentTarget.checked);
  //   if (e.currentTarget.checked) {
  //     setPointValue(points.toString());
  //   } else {
  //     setPointValue('0');
  //   }
  // };

  useEffect(() => {
    if (parseFloat(pointValue.replaceAll(',', '')) === TotalPoint) {
      setPercent(100);
    } else {
      const val = ((parseFloat(pointValue.replaceAll(',', '')) || 0) / TotalPoint) * 100;
      setPercent(val);
      // setCheckAll(false);
    }
  }, [pointValue, points, TotalPoint]);

  useEffect(() => {
    if (TotalPoint) {
      setPointValue(formatNumber(TotalPoint.toString()));
      setPercent(100);
    }
  }, [TotalPoint]);

  return (
    <div className='t-pointReward bg-white rounded-xl overflow-hidden'>
      <div className='top bg-blueRyb px-6 py-3 text-white'>
        <h3 className='text-xl font-semibold'>Số dư điểm thưởng</h3>
        <p className='mt-1 text-md lg:text-base'>Bạn đang có {formatDecimalNumber(points.toString())} Bcoin ({formatVndCurrency(points)})</p>
      </div>
      {
        points >= 1000 && (
          <div className='content p-4 lg:p-6'>
            <p className='font-semibold text-gunmetal'>Điểm thưởng được sử dụng (1 điểm = 1 VND)</p>
            <p className='text-roman-silver text-sm font-normal mt-2 min-h-[38px]'>Bạn được sử dụng{' '}
              <span className='font-semibold text-persian-green'>
                {formatDecimalNumber(TotalPoint?.toString())} Bcoin
                ({formatVndCurrency(TotalPoint)})
              </span>
              {' '} cho đơn hàng này
            </p>
            <div className='my-3'>
              <PointRange
                percent={percent}
                setPercent={(value) => handlePercentChange(value)}
                isRepayment={isRepayment}
              />
            </div>
            <div className='flex flex-row gap-2'>
              <div className='w-full'>
                <Input
                  value={pointValue}
                  type='tel'
                  handleClear={handleClear}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder='Nhập số điểm'
                  className={`${pointValue ? ' font-semibold' : ''}`}
                  error={error}
                  disabled={isRepayment}
                />
              </div>
              <Button
                type='button'
                size='large'
                disabled={!pointValue || isRepayment}
                className={`button-base-style max-w-[112px] ${pointValue && !isRepayment ? 'text-white bg-blueRyb hover:bg-blue-400' : 'bg-anti-flash-white2 text-slate-gray'}`}
                onClick={submitPoint}
              >
                Áp dụng
              </Button>
            </div>
            {/* <p className='mt-2 text-md text-feldgrau'>Tỷ lệ quy đổi hiện tại: 1 điểm = 1 VND</p> */}
            <p className='mt-2 text-md text-feldgrau'>Bấm Áp Dụng để sử dụng điểm thưởng cho thanh toán.</p>
            {/* <div className='mt-2'>
          <Checkbox label=' Sử dụng tất cả điểm đang có' checked={checkAll} onChange={handleCheckAll} />
        </div> */}
            {appliedBcoin > 0 && !error && <p className='text-medium-turquoise text-md mt-2'>Bạn đã áp dụng {formatDecimalNumber(appliedBcoin)} Bcoin</p>}
          </div>
        )
      }
    </div>
  );
};

export default PointReward;
