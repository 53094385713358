import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GeneralState, LanguageType, ModalVariantType } from './types';

import flagVn from 'assets/images/vn-flag.png';
import { IProvinces } from 'types/users';

const initialState: GeneralState = {
  language: {
    label: 'VN',
    value: 'vi',
    flag: flagVn,
  },
  fixedMenu: false,
  provinces: [],
  loading: false,
  cartStep: 1,
  modalVisible: {
    confirm: {
      visible: false,
      title: '',
      message: '',
    },
  },
  manufacturer: {
    title: '',
    description: '',
    id: null,
    image: '',
  },
  category: {
    title: '',
    id: null,
    image: '',
  },
  product: {
    title: '',
    id: null,
    image: '',
  },
};

const generalSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    switchLanguage: ($state, action: PayloadAction<LanguageType>) => {
      $state.language = action.payload;
    },
    onFixedMenu: ($state, action: PayloadAction<boolean>) => {
      $state.fixedMenu = action.payload;
    },
    setCartStep: ($state, action: PayloadAction<number>) => {
      $state.cartStep = action.payload;
    },

    setLoading: ($state, action: PayloadAction<boolean>) => {
      $state.loading = action.payload;
    },
    openConfirmModal(
      $state,
      action: PayloadAction<{
        variant: ModalVariantType;
        title: string;
        message: string;
      }>
    ) {
      const { variant, title, message } = action.payload;
      $state.modalVisible[variant] = { visible: true, title, message };
    },
    closeConfirmModal($state, action: PayloadAction<ModalVariantType>) {
      $state.modalVisible[action.payload].visible = false;
    },
    setProvinces: ($state, action: PayloadAction<IProvinces[]>) => {
      $state.provinces = action.payload;
    },
    setManufacturer: ($state, action: PayloadAction<any>) => {
      $state.manufacturer = action.payload;
    },
    setCategory: ($state, action: PayloadAction<any>) => {
      $state.category = action.payload;
    },

    setProductDetail: ($state, action: PayloadAction<any>) => {
      $state.product = action.payload;
    },
  },
});

export const {
  switchLanguage: switchLanguageReducer,
  onFixedMenu: fixedMenuReducer,
  setLoading: setLoadingReducer,
  setCartStep: setCartStepReducer,
  openConfirmModal: openConfirmModalReducer,
  closeConfirmModal: closeConfirmModalReducer,
  setProvinces,
  setManufacturer,
  setCategory,
  setProductDetail,
} = generalSlice.actions;

export default generalSlice.reducer;
