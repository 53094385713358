/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Outlet, ScrollRestoration, useLocation, useSearchParams
} from 'react-router-dom';

import menuDumData from 'assets/dummy';
import logo from 'assets/images/logo.png';
import Loading from 'components/molecules/Loading';
import Header from 'components/organisms/Header';
import Footer from 'components/templates/Footer';
import useHeaderHeight from 'hooks/useHeaderHeight';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { getCategoryTreeApi } from 'services/home';
import { setIsRepayment, setRepaymentData } from 'store/cart';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './App.scss';
import { ICategories } from 'types/home';
import SLUG_PARAMS, { policyUrls } from 'utils/constants';
import { analytics } from 'utils/firebaseConfig';

import { logEvent } from 'firebase/analytics';

const App: React.FC = () => {
  const { fixedMenu, loading } = useAppSelector((state) => state.general);
  const { user } = useAppSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get('returnUrl');
  const isLoginFirst = searchParams.get('isLoginFirst');
  const headerHeight = useHeaderHeight();
  const { width } = useWindowDimensions();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [categories, setCategories] = useState<ICategories[]>();
  const fetchCategoriesTree = async () => {
    try {
      const response = await getCategoryTreeApi();
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategoriesTree();
  }, []);

  useEffect(() => {
    const footerEle = document.querySelector('.t-footer');
    if (width <= 1199 && location.pathname === SLUG_PARAMS.CART) {
      footerEle?.classList.add('hidden');
    } else {
      footerEle?.classList.remove('hidden');
    }
  }, [width, location]);

  useEffect(() => {
    if (
      location?.pathname !== '/cart'
      && !location?.pathname.startsWith('/customer-info/order-detail')
      && !location?.pathname.startsWith('/order-completed')
    ) {
      dispatch(setIsRepayment(false));
      dispatch(setRepaymentData(null));
    }
  }, [location]);

  const trackingPageView = (analytics: any, location: any) => {
    try {
      logEvent(analytics, 'page_view', {
        page_path: location?.pathname,
        page_title: document?.title || 'BidaHub',
      });
    } catch (error) {
      console.error('Failed to track page view:', error);
    }
  };

  useEffect(() => {
    trackingPageView(analytics, location);
  }, [location]);

  return (
    <>
      {loading && <Loading />}
      {/* <Header menus={menuDumData} isLogged={!!user} /> */}
      <Header menus={categories as ICategories[]} isLogged={!!user} />

      <main className='wrapper' style={fixedMenu ? { marginTop: `${headerHeight}px` } : {}}>
        <ScrollRestoration />
        <Outlet />
      </main>
      <Footer
        logo={logo}
        address='Địa chỉ trụ sở kinh doanh: RG.B-09.21 (Officeted) Tầng 9, Tháp B, Chung cư Rivergate Residence, 151 - 155 Bến Văn Đồn, Phường 6, Quận 4, TP.HCM'
        phone='0949.696.696'
        email='support@bidahub.com'
        customerSupport={policyUrls}
      />
    </>
  );
};

export default App;
