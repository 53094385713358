/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { debounce, forEach } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { productVariants } from 'assets/dummy';
import chervonDown from 'assets/icons/ic_chevron_down.svg';
import Checkbox from 'components/atoms/Checkbox';
import Icon from 'components/atoms/Icon';
import Pulldown from 'components/molecules/Pulldown';
import QuantitySelector from 'components/molecules/QuantitySelector';
import useCart from 'hooks/useCart';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { addToCartApi, updateCartApi } from 'services/cart';
import { getProductDetailApi } from 'services/product';
import { updateFromWishList } from 'services/wishlist';
import { setCartData, setOrderTotals } from 'store/cart';
import { useAppDispatch } from 'store/hooks';
import { IAddToCartRequest } from 'types/cart';
import { IProductAtribute } from 'types/product';
import SLUG_PARAMS from 'utils/constants';
import {
  decodeHtmlEntities, parseAtributeInfoValue, parseAttributes, parseOrderTotal
} from 'utils/functions';

interface IAttributes {
  attributeId: string;
  value: string;
}

export function convertAttribute(text: string) {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  // return textArea.value;
  const decodedText = textArea.value;
  return decodedText.replace(/<br\s*\/?>/gi, '\n');
}

export interface ProductSummaryType {
  id: number;
  name: string;
  image: string;
  price: number;
  discount?: number;
  count?: number;
  attributeInfo: string;
  wishlistId?: number;
  productId?: number;
  productAttributes?: IProductAtribute[];
  inWishlist?: boolean;
}

interface ProductTableProps {
  products: ProductSummaryType[];
  noCheck?: boolean;
  isStatic?: boolean;
  onDelete?: (id: number) => void;
  onSelectVariantMobile?: (id: number) => void;
  hideLike?: boolean;
  handleSelectItem?: (id: number[]) => void;
  isWishList?: boolean
  onQuantityChange?: any;
  isRepayment?: boolean
  selectItem?: any[]
  discountCoupon?: string
}

const ProductTable: React.FC<ProductTableProps> = ({
  products, noCheck, isStatic, onDelete, onSelectVariantMobile, hideLike, handleSelectItem,
  isWishList, onQuantityChange = () => { }, isRepayment, selectItem = [], discountCoupon
}) => {
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set());
  const [quantities, setQuantities] = useState<Record<number, number>>({});
  const { width } = useWindowDimensions();
  const { handleFetchShoppingCart, handleFetchWishList } = useCart();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const handleSelect = (productId: number) => {
    const newSelectedItems = new Set(selectedItems);
    if (newSelectedItems.has(productId)) {
      newSelectedItems.delete(productId);
    } else {
      newSelectedItems.add(productId);
    }
    setSelectedItems(newSelectedItems);
    if (handleSelectItem) {
      handleSelectItem(Array.from(newSelectedItems));
    }
  };

  useEffect(() => {
    const isPathBlocked = location.pathname !== SLUG_PARAMS.CART
      && !location.pathname.startsWith('/customer-info/order-detail')
      && !location.pathname.startsWith('/order-completed');

    if (selectItem.length === 0 && isPathBlocked) {
      setSelectedItems(new Set());
    }
  }, [location, selectItem]);

  const formatPrice = (price: number): string => price?.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });

  const changeCartProductQuantity = async (value: any, producId: number) => {
    try {
      const params = {
        FormValues: [
          {
            Key: `itemquantity${producId}`,
            Value: value
          }
        ]
      };
      const response = await updateCartApi(params);
      dispatch(setCartData(response?.data?.cart?.items));
      dispatch(setOrderTotals(parseOrderTotal(
        response?.data?.cart?.items,
        response?.data?.orderTotals
      )));
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const changeWishListProductQuantity = async (value: any, producId: number) => {
    try {
      const params = {
        FormValues: [
          {
            Key: `itemquantity${producId}`,
            Value: value
          }
        ]
      };
      await updateFromWishList(params as any);
      handleFetchWishList();
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const changeCartProductAtribute = async (
    currentAtributes: any,
    value: any,
    attributeId: string,
    product: ProductSummaryType,
  ) => {
    try {
      const formValues = [
        {
          Key: `addtocart_${product.productId}.UpdatedShoppingCartItemId`,
          Value: product.id,
        },
        {
          Key: `addtocart_${product.productId}.EnteredQuantity`,
          Value: product.count
        },
      ];
      if (currentAtributes) {
        forEach(currentAtributes, (item: any) => {
          if (item.attributeId === attributeId) {
            formValues.push({
              Key: `product_attribute_${attributeId}`,
              Value: value,
            });
          } else {
            formValues.push({
              Key: `product_attribute_${item.attributeId}`,
              Value: item.value,
            });
          }
        });
      }
      const params = {
        data: {
          FormValues: formValues,
        },
        productId: Number(product?.productId),
        type: 1,
      };
      await addToCartApi(params);
      handleFetchShoppingCart();
    } catch (error: any) {
      toast.error('Tạm hết hàng');
      // toast.error(error?.response?.data?.ErrorList?.[0] === 'Out of stock' ? 'Tạm hết hàng' : error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const changeWishlistProductAtribute = async (
    currentAtributes: any,
    value: any,
    attributeId: string,
    product: ProductSummaryType,
  ) => {
    try {
      const formValues = [
        {
          Key: `addtocart_${product?.id}.UpdatedShoppingCartItemId`,
          Value: product.wishlistId,
        },
        {
          Key: `addtocart_${product.id}.EnteredQuantity`,
          Value: product.count
        },
      ];
      if (currentAtributes) {
        forEach(currentAtributes, (item: any) => {
          if (item.attributeId === attributeId) {
            formValues.push({
              Key: `product_attribute_${attributeId}`,
              Value: value,
            });
          } else {
            formValues.push({
              Key: `product_attribute_${item.attributeId}`,
              Value: item.value,
            });
          }
        });
      }
      const params = {
        data: {
          FormValues: formValues,
        },
        productId: Number(product?.id),
        type: 2,
      };
      await addToCartApi(params);
      handleFetchWishList();
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleUpdateCartQuantity = debounce(
    changeCartProductQuantity,
    500
  );

  const handleUpdateWishListQuantity = debounce(
    changeWishListProductQuantity,
    500
  );

  const handleSelectAll = () => {
    if (selectedItems.size === products.length) {
      setSelectedItems(new Set());
      if (handleSelectItem) {
        handleSelectItem([]);
      }
    } else {
      const allSelectedIds = products.map((p) => p.wishlistId as number);
      setSelectedItems(new Set(allSelectedIds));
      if (handleSelectItem) {
        handleSelectItem(allSelectedIds);
      }
    }
  };

  const [attributes, setAttributes] = useState<IAttributes[]>([]);
  const [productQuantity, setProductQuantity] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const handleAddToCart = async (params: IAddToCartRequest, type?: number) => {
    try {
      setLoading(true);
      await addToCartApi(params);
      toast.success('Sản phẩm đã được thêm vào danh sách yêu thích');
      setLoading(false);
      if (type === 1) {
        handleFetchShoppingCart();
      } else {
        handleFetchWishList();
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleRemoveProductFromWishList = async (id: number) => {
    try {
      const params = {
        formValues: [
          {
            key: 'removefromcart',
            value: `${id}`
          }
        ],
        message: '',
      };

      await updateFromWishList(params);
      handleFetchWishList();
      handleFetchShoppingCart();
      toast.success('Đã xoá sản phẩm khỏi danh sách yêu thích');
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleCheckProduct = async (id?: number, product?: any) => {
    try {
      const response = await getProductDetailApi(Number(id));
      const attributeProduct: any = parseAttributes(product?.attributeInfo, product?.productAttributes) || [];
      if (response.data.productAttributes.length >= 0) {
        const formValues = [
          {
            Key: `addtocart_${id}.EnteredQuantity`,
            Value: productQuantity.toString(),
          },
        ];
        attributeProduct?.forEach((atribute: any) => {
          formValues.push({
            Key: `product_attribute_${atribute.attributeId}`,
            Value: atribute.value,
          });
        });
        await handleAddToCart({
          productId: Number(id),
          type: 2,
          data: {
            FormValues: formValues,
          }
        }, 2);
        handleFetchShoppingCart();
      }
      setProductQuantity(1);
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  if (width >= 992) {
    return (
      <table className='t-productTable w-full bg-white rounded-xl'>
        <thead>
          <tr className='text-feldgrau'>
            {
              !noCheck && !isStatic
              && (
                <th className='p-3 font-semibold'>
                  <Checkbox
                    checked={selectedItems.size === products.length}
                    onChange={handleSelectAll}
                  />

                </th>
              )
            }
            {
              isStatic ? (
                <>
                  <th className='p-3 font-semibold text-left w-[40%]'>Sản phẩm</th>
                  <th className='p-3 font-semibold text-center w-[15%]'>Giá</th>
                  <th className='p-3 font-semibold text-center w-[20%]'>Số lượng</th>
                  <th className='p-3 font-semibold text-right w-[20%]'>Tổng</th>
                </>
              ) : (
                <>
                  <th className='p-3 font-semibold text-left w-[50%]'>Sản phẩm</th>
                  <th className='p-3 font-semibold text-center w-[15%]'>Giá</th>
                  <th className='p-3 font-semibold text-center w-[15%]'>Số lượng</th>
                  <th className='p-3 font-semibold text-right w-[15%]'>Tổng</th>
                </>
              )
            }

          </tr>
        </thead>
        <tbody>
          {products?.map((product) => {
            const quantity = product?.count || 1;
            const discount = product?.discount || 0;
            const total = product.price * quantity - discount;

            return (
              <tr key={product?.id} className='border-t text-gunmetal'>
                {
                  !noCheck && !isStatic && (
                    <td className='p-3'>
                      <Checkbox
                        checked={selectedItems.has(isWishList ? product?.wishlistId as number
                          : product?.id)}
                        onChange={() => handleSelect(isWishList ? product?.wishlistId as number
                          : product?.id)}
                      />
                    </td>
                  )
                }
                <td className='p-3 flex items-center space-x-4 image'>
                  <a href={`${SLUG_PARAMS.PRODUCTS_DETAIL}/${isWishList ? product?.id : product?.productId}`} className='aspect-1 w-[72px] block rounded-lg overflow-hidden'>
                    <img src={product?.image} alt={product?.name} className='w-[72px] h-[72px] object-contain' />
                  </a>
                  <div className='flex-1'>
                    {
                      isStatic ? (
                        <div className='flex flex-col gap-2 text-md text-feldgrau'>
                          <p className='product-name text-base text-raisin-black'>
                            <a href={`${SLUG_PARAMS.PRODUCTS_DETAIL}/${isWishList ? product?.id : product?.productId}`}>{product?.name}</a>
                          </p>
                          <p className='whitespace-pre-wrap'>{convertAttribute(product?.attributeInfo)}</p>
                        </div>
                      ) : (
                        <>
                          <p className='product-name'><a href={`${SLUG_PARAMS.PRODUCTS_DETAIL}/${isWishList ? product?.id : product?.productId}`}>{product?.name}</a></p>
                          {product?.attributeInfo?.length > 0 && (
                            <div className='flex gap-x-2 flex-wrap'>
                              {product?.productAttributes?.map((attribute, index) => {
                                const options = attribute?.values?.map(
                                  (item) => ({
                                    id: item.id,
                                    label: item.name,
                                    value: item.id,
                                  })
                                );
                                const idx = parseAtributeInfoValue(
                                  product?.attributeInfo,
                                  options,
                                  index
                                );
                                const currentAtributes = parseAttributes(
                                  product.attributeInfo,
                                  product.productAttributes
                                );
                                return (
                                  <Pulldown
                                    placeholder='Chọn options'
                                    size='sm'
                                    value={options?.[idx]?.value as any}
                                    onChange={(e) => (!isWishList ? changeCartProductAtribute(
                                      currentAtributes,
                                      e.target.value,
                                      attribute.id,
                                      product
                                    ) : changeWishlistProductAtribute(
                                      currentAtributes,
                                      e.target.value,
                                      attribute.id,
                                      product
                                    ))}
                                    options={options}
                                    className='text-md rounded-lg'
                                  />
                                );
                              })}
                            </div>
                          )}
                        </>
                      )
                    }
                    {
                      !isStatic && (
                        <div className='flex mt-3 gap-4'>
                          {!hideLike && !isRepayment && (
                            <div className='liked'>
                              <button
                                onClick={product?.inWishlist
                                  ? () => handleRemoveProductFromWishList(product?.wishlistId as number)
                                  : () => handleCheckProduct(product.productId as number, product)}
                                className='w-9 h-9 rounded-lg flex items-center justify-center bg-anti-flash-white2 base-transition hover:bg-gray-300'
                              >
                                {/* TODO : Check if product is liked with `outlinedHeart2` for unliked or `filledHeart` for liked */}
                                <Icon iconName={product?.inWishlist ? 'filledHeart' : 'outlinedHeart2'} size='24x24' />
                              </button>
                            </div>
                          )}
                          {!isRepayment && (
                            <div className='trash'>
                              <button
                                onClick={() => {
                                  onDelete?.(isWishList ? product?.wishlistId as number
                                    : product?.id);
                                }}
                                className='w-9 h-9 rounded-lg flex items-center justify-center bg-anti-flash-white2 base-transition hover:bg-gray-300'
                              >
                                <Icon iconName='trashOutlined' size='24x24' />
                              </button>
                            </div>
                          )}
                        </div>
                      )
                    }
                  </div>
                </td>
                <td className='p-3 text-center price'>{formatPrice(product?.price)}</td>
                <td className='p-3 text-center'>
                  <div className='flex justify-center'>
                    {
                      isStatic ? product?.count : (
                        <QuantitySelector
                          initialQuantity={product?.count}
                          onChange={(value) => {
                            !isWishList
                              ? (
                                handleUpdateCartQuantity(value, product?.id),
                                onQuantityChange(value as number)
                              )
                              : handleUpdateWishListQuantity(value, product?.wishlistId);
                          }}
                          isRepayment={isRepayment}
                        />
                      )
                    }
                  </div>
                </td>
                <td className='p-3 text-right'>
                  <span className='font-semibold'>{formatPrice(total)}</span>
                  {discount > 0 && (
                    <p className='text-neon-fuchsia text-sm'>Tiết kiệm: {formatPrice(discount)}</p>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
        {discountCoupon && (
          <p className='px-4 my-2 text-md text-feldgrau'>
            Bạn đã được giảm giá từ voucher{' '}
            <span className='text-black'> {discountCoupon}</span>
          </p>
        )}
      </table>
    );
  }
  // Mobile version
  return (
    <div className='t-productTable__mobile bg-white rounded-lg overflow-hidden'>
      <div className='px-4 py-3 border-b'>
        <h2 className='md:text-lg font-semibold'>Sản phẩm</h2>
      </div>
      {products?.map((product) => {
        const quantity = product?.count || 1;
        const discount = product?.discount || 0;
        const total = product.price * quantity - discount;

        return (
          <div key={product?.id} className='flex flex-col border-b px-2 py-4'>
            <div className='flex items-center space-x-4'>
              {
                !noCheck && !isStatic
                && (
                  <Checkbox
                    checked={selectedItems.has(isWishList ? product?.wishlistId as number
                      : product?.id)}
                    onChange={() => handleSelect(isWishList ? product?.wishlistId as number
                      : product?.id)}
                  />
                )
              }
              <div className='px-2'>
                <a href={`${SLUG_PARAMS.PRODUCTS_DETAIL}/${product?.productId}`} className='aspect-1 w-[72px] block rounded-lg overflow-hidden'>
                  <img src={product?.image} alt={product?.name} className='w-[72px] h-[72px] object-contain' />
                </a>
              </div>
              {
                isStatic
                  ? (
                    <div className='flex-1'>
                      <p className='product-name'><a href={`${SLUG_PARAMS.PRODUCTS_DETAIL}/${isWishList ? product?.id : product?.productId}`}>{product?.name}</a></p>
                      <div className='flex flex-col gap-2 text-md text-feldgrau'>
                        <p>{convertAttribute(product?.attributeInfo as string)}</p>
                        {/* <p>Size: M</p>
                        <p>Tùy chọn: Tay trái</p> */}
                        <p className='text-gunmetal text-base font-semibold'>Giá: {formatPrice(product?.price)}</p>
                        {
                          product?.discount as number > 0 && <p className='text-neon-fuchsia text-sm'>Tiết kiệm: {formatPrice(product?.discount as number)}</p>
                        }
                        <p>Số lượng: {product?.count}</p>
                      </div>
                    </div>
                  )
                  : (
                    <div className='flex-1'>
                      <p className='product-name'><a href={`${SLUG_PARAMS.PRODUCTS_DETAIL}/${isWishList ? product?.id : product?.productId}`}>{product?.name}</a></p>
                      <div className='flex space-x-2 flex-wrap'>
                        {product?.attributeInfo?.length > 0 && (
                          <div className='flex gap-x-2 flex-wrap'>
                            {product?.productAttributes?.map((attribute, index) => {
                              const options = attribute?.values?.map(
                                (item) => ({
                                  id: item.id,
                                  label: item.name,
                                  value: item.id,
                                })
                              );
                              const idx = parseAtributeInfoValue(
                                product?.attributeInfo,
                                options,
                                index
                              );
                              const currentAtributes = parseAttributes(
                                product.attributeInfo,
                                product.productAttributes
                              );
                              return (
                                <Pulldown
                                  placeholder='Chọn options'
                                  size='sm'
                                  value={options?.[idx]?.value as any}
                                  onChange={(e) => (!isWishList ? changeCartProductAtribute(
                                    currentAtributes,
                                    e.target.value,
                                    attribute.id,
                                    product
                                  ) : changeWishlistProductAtribute(
                                    currentAtributes,
                                    e.target.value,
                                    attribute.id,
                                    product
                                  ))}
                                  options={options}
                                  className='text-md rounded-lg'
                                />
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <div className='flex flex-col gap-2'>
                        <p className='mt-2'>Giá: {formatPrice(product.price)}</p>
                        <p className='font-semibold'>Tổng: {formatPrice(total)}</p>
                        {discount > 0 && (
                          <p className='text-carmine-pink text-sm'>Tiết kiệm: {formatPrice(discount)}</p>
                        )}
                        <div className='bottom flex justify-between items-center'>
                          <QuantitySelector
                            initialQuantity={product?.count}
                            isRepayment={isRepayment}
                            onChange={(value) => {
                              !isWishList
                                ? (
                                  handleUpdateCartQuantity(value, product?.id),
                                  onQuantityChange(value as number)
                                )
                                : handleUpdateWishListQuantity(value, product?.wishlistId);
                            }}
                          />
                          <div className='flex gap-4'>
                            {!hideLike && !isRepayment && (
                              <div className='liked'>
                                <button
                                  className='w-9 h-9 rounded-lg flex items-center justify-center bg-anti-flash-white2 base-transition hover:bg-gray-300'
                                  onClick={() => (product?.inWishlist ? handleRemoveProductFromWishList(product?.wishlistId as number) : handleCheckProduct(product.productId as number, product))}
                                >
                                  {/* TODO : Check if product is liked with `outlinedHeart2` for unliked or `filledHeart` for liked */}
                                  <Icon iconName={product?.inWishlist ? 'filledHeart' : 'outlinedHeart2'} size='24x24' />
                                </button>
                              </div>
                            )}
                            {
                              !isRepayment && (
                                <div className='trash'>
                                  <button
                                    onClick={() => {
                                      onDelete?.(isWishList ? product?.wishlistId as number
                                        : product?.id);
                                    }}
                                    className='w-9 h-9 rounded-lg flex items-center justify-center bg-anti-flash-white2 base-transition hover:bg-gray-300'
                                  >
                                    <Icon iconName='trashOutlined' size='24x24' />
                                  </button>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              }
            </div>
          </div>

        );
      })}
      {discountCoupon && (
        <p className='text-center my-2 text-md text-feldgrau'>
          Bạn đã được giảm giá từ voucher{' '}
          <span className='text-black'> {discountCoupon}</span>
        </p>
      )}
    </div>

  );
};

export default ProductTable;
