/* eslint-disable import/prefer-default-export */
import { useCallback, useRef } from 'react';

import { updateCartApi } from 'services/cart';
import { setCartData, setOrderTotals } from 'store/cart';
import {
  closeConfirmModalReducer,
  openConfirmModalReducer,
  setLoadingReducer,
} from 'store/general';
import { useAppDispatch } from 'store/hooks';
import { removeFromCartAnalytics } from 'utils/analytics/ecommerce';
import { parseOrderTotal } from 'utils/functions';

export const useDeleteTableProduct = () => {
  const variant = 'confirm';
  const dispatch = useAppDispatch();
  const productId = useRef<number>(0);

  const openConfirmModal = useCallback(
    (title: string, message: string, id: number) => {
      dispatch(openConfirmModalReducer({ variant, title, message }));
      productId.current = id;
    },
    [dispatch, variant]
  );
  const handleConfirm = useCallback(async () => {
    dispatch(closeConfirmModalReducer(variant));
    dispatch(setLoadingReducer(true));
    try {
      const params = {
        FormValues: [
          {
            Key: 'removefromcart',
            Value: String(productId.current),
          },
        ],
      };
      const response = await updateCartApi(params);
      dispatch(setCartData(response?.data?.cart?.items));
      dispatch(
        setOrderTotals(
          parseOrderTotal(
            response?.data?.cart?.items,
            response?.data?.orderTotals
          )
        )
      );
      removeFromCartAnalytics({
        id: productId.current,
      });
    } catch (error) {
      console.error('Failed to delete product', error);
    } finally {
      dispatch(setLoadingReducer(false));
    }
  }, [dispatch, productId]);

  return { openConfirmModal, handleConfirm };
};
